import i18n from "i18next";
// import { reactI18nextModule } from "react-i18next";
import { initReactI18next } from "react-i18next";

import detector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationES from "./locales/es/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationIT from "./locales/it/translation.json";

// the translations
const resources = {
  gb: {
    translation: translationEN,
  },
  us: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
  es: {
    translation: translationES,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // whitelist: ["en", "fr", "es"],
    fallbackLng: "en", // use en if detected lng is not available
    preload: ["en", "fr"],
    resources,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
