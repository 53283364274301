import * as actionTypes from "./actionTypes";

export const fetchUserRequest = (
  payload: User.FetchRequestPayload
): User.FetchRequest => {
  return {
    type: actionTypes.FETCH_USER_REQUEST,
    payload,
  };
};

export const fetchCurrentGroupSuccess = (
  payload: User.FetchCurrentGroupSuccessPayload
): User.FetchCurrentGroupSuccess => {
  return {
    type: actionTypes.FETCH_CURRENT_GROUP_SUCCESS,
    // @ts-ignore
    payload,
  };
};

export const fetchUserWithLogosSuccess = (
  payload: User.FetchWithLogosSuccessPayload
): User.FetchWithLogosSuccess => {
  return {
    type: actionTypes.FETCH_USER_WITH_LOGOS_SUCCESS,
    payload,
  };
};

export const fetchUserWithoutLogosSuccess = (
  payload: User.FetchWithoutLogosSuccessPayload
): User.FetchWithoutLogosSuccess => {
  return {
    type: actionTypes.FETCH_USER_WITHOUT_LOGOS_SUCCESS,
    payload,
  };
};

export const fetchUserFailure = (
  payload: User.UpdateFailurePayload
): User.FetchFailure => {
  return {
    type: actionTypes.FETCH_USER_FAILURE,
    payload,
  };
};

export const countDialsRequest = (
  payload: User.CountRequestPayload
): User.CountRequest => {
  return {
    type: actionTypes.COUNT_DIALS_REQUEST,
    payload,
  };
};

export const countDialsSuccess = (
  payload: User.CountSuccessPayload
): User.CountSuccess => {
  return {
    type: actionTypes.COUNT_DIALS_SUCCESS,
    payload,
  };
};

export const countDialsFailure = (
  payload: User.FetchFailurePayload
): User.FetchFailure => {
  return {
    type: actionTypes.COUNT_DIALS_FAILURE,
    payload,
  };
};

export const updateUserRequest = (
  payload: User.UpdateRequestPayload
): User.UpdateRequest => {
  return {
    type: actionTypes.UPDATE_USER_REQUEST,
    payload,
  };
};

export const updateUserSuccess = (
  payload: User.UpdateSuccessPayload
): User.UpdateSuccess => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload,
  };
};

export const updateUserFailure = (
  payload: User.UpdateFailurePayload
): User.UpdateFailure => {
  return {
    type: actionTypes.UPDATE_USER_FAILURE,
    payload,
  };
};

export const updateOneGroupsRequest = (
  payload: Group.UpdateRequestPayload
): Group.UpdateRequest => {
  return {
    type: actionTypes.UPDATE_GROUP_REQUEST,
    payload,
  };
};

export const updateOneGroupsSuccess = (
  payload: Group.UpdateSuccessPayload
): Group.UpdateSuccess => {
  return {
    type: actionTypes.UPDATE_GROUP_SUCCESS,
    payload,
  };
};

export const updateAllGroupsRequest = (
  payload: Group.UpdateAllRequestPayload
): Group.UpdateAllRequest => {
  return {
    type: actionTypes.UPDATE_ALLGROUPS_REQUEST,
    payload,
  };
};

export const updateAllGroupsSuccess = (
  payload: Group.UpdateAllSuccessPayload
): Group.UpdateAllSuccess => {
  return {
    type: actionTypes.UPDATE_ALLGROUPS_SUCCESS,
    payload,
  };
};

export const clearAllUser = (): User.ClearAll => {
  return {
    type: actionTypes.CLEAR_ALL_USER,
  };
};

export const fetchGroupRequest = (
  payload: Group.FetchRequestPayload
): Group.FetchRequest => {
  return {
    type: actionTypes.FETCH_GROUP_REQUEST,
    payload,
  };
};

// ================ Dials

export const updateDialRequest = (
  payload: Dial.UpdateRequestPayload
): Dial.UpdateRequest => {
  return {
    type: actionTypes.UPDATE_DIAL_REQUEST,
    payload,
  };
};

export const deleteDialRequest = (
  payload: Dial.DeleteRequestPayload
): Dial.DeleteRequest => {
  return {
    type: actionTypes.DELETE_DIAL_REQUEST,
    payload,
  };
};

export const createDialRequest = (
  payload: Dial.CreateRequestPayload
): Dial.CreateRequest => {
  return {
    type: actionTypes.CREATE_DIAL_REQUEST,
    payload,
  };
};
