import React from "react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Account from "./Account";
import Buttons from "./Buttons";
import Version from "./Version";

const useStyles = makeStyles<Theme>((theme) => ({
  account: {
    flexGrow: 0,
    // height: "70px",
    backgroundColor: theme.palette.primary.main,
  },
}));

type DrawerAccountPanelProps = { user: Omit<User.UserData, "groups"> };

const DrawerAccountPanel = ({ user }: DrawerAccountPanelProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item className={classes.account}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Version />
        </Grid>
        <Grid item>
          <Buttons />
        </Grid>
      </Grid>
      <Account user={user} />
    </Grid>
  );
};

export default DrawerAccountPanel;
