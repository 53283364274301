// @ts-nocheck
import createSagaMiddleWare from "redux-saga";
import rootSagas from "../actions/rootSagas";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";

import storage from "localforage";

import { combineReducers } from "redux";
import users from "../actions/users/reducer";
import auth from "../actions/auth/reducer";
import global from "../actions/global/reducer";
import preferences from "../actions/preferences/reducer";

export type IRootState = {
  global: Global.State;
  auth: Auth.State;
  users: User.State;
  preferences: Preference.State;
};

const sagaMiddleware = createSagaMiddleWare();

const authPersistConfig = {
  key: "auth",
  storage,
};

const usersPersistConfig = {
  key: "users",
  storage,
};

const preferencesPersistConfig = {
  key: "preferences",
  storage,
};

type RootReducer = (state: IRootState, action: AnyAction) => IRootState;

const rootReducer: RootReducer = combineReducers({
  global,
  auth: persistReducer(authPersistConfig, auth),
  users: persistReducer(usersPersistConfig, users),
  preferences: persistReducer(preferencesPersistConfig, preferences),
});

// const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSagas);

export default store;

export type AppDispatch = typeof store.dispatch;
