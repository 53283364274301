import localforage from "localforage";

export type IsJsonString = (v: string) => boolean;

const isJsonString: IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

type GetFromStorage = (name: string) => string | null;

export const getFromStorage: GetFromStorage = (name) => {
  const value = localStorage.getItem(name);
  // always return a string so need to be convert afterward on program
  return value;
};

type SetFromStorage = (name: string, value: any) => void;

export const setInStorage: SetFromStorage = (name, value) => {
  const storedValue = getFromStorage(name) as string;

  const newIsObj = typeof value === "object";
  const oldIsObj = isJsonString(storedValue);

  const oldValue = oldIsObj ? JSON.parse(storedValue) : storedValue;

  if (newIsObj) {
    if (oldIsObj) {
      localStorage.setItem(name, JSON.stringify({ ...oldValue, ...value }));
    } else {
      localStorage.setItem(name, JSON.stringify(value));
    }
  } else {
    localStorage.setItem(name, value);
  }
};

type ClearAllStorage = () => void;

export const clearAllStorage: ClearAllStorage = () => {
  localStorage.clear();
  localforage.clear();
};
