import * as actionTypes from "./actionTypes";

const defaultState: Global.State = {
  message: { messageType: "", showMessage: false },
  error: { error: { data: {} }, showError: false },
  alert: { alertType: "", showAlert: false },
  isLoading: 0,
  update: false,
  largeScreen: true,
  tabValue: 0,
  currentGroup: "",
  offlineMode: false,
};

export default function globalReducer(
  state = defaultState,
  action: Global.Actions
): Global.State {
  switch (action.type) {
    //
    // LOADER
    case actionTypes.SHOW_LOADER:
      return { ...state, isLoading: state.isLoading + 1 };

    case actionTypes.HIDE_LOADER:
      return { ...state, isLoading: state.isLoading - 1 };

    // Network Mode
    case actionTypes.SET_OFFLINE_MODE:
      return { ...state, offlineMode: true };

    case actionTypes.SET_ONLINE_MODE:
      return { ...state, offlineMode: false };

    // API
    case actionTypes.API_FAILURE: {
      return {
        ...state,
        isLoading: 0,
        error: { error: action.payload.response, showError: true },
      };
    }

    // MESSAGE
    case actionTypes.SHOW_MESSAGE:
      return {
        ...state,
        message: { messageType: action.payload.messageType, showMessage: true },
      };

    case actionTypes.HIDE_MESSAGE:
      return {
        ...state,
        message: { showMessage: false },
      };

    // ALERT
    case actionTypes.SHOW_ALERT:
      return {
        ...state,
        alert: { ...action.payload, showAlert: true },
      };

    case actionTypes.CONFIRM_ALERT:
      return {
        ...state,
        alert: { showAlert: false },
      };

    case actionTypes.CANCEL_ALERT:
      return {
        ...state,
        alert: { showAlert: false },
      };

    case actionTypes.HIDE_ERROR:
      return {
        ...state,
        error: defaultState.error,
      };

    case actionTypes.LARGE_SCREEN:
      return { ...state, largeScreen: action.payload.largeScreen };

    case actionTypes.SET_TABVALUE:
      return { ...state, tabValue: action.payload.tabValue };

    default:
      return state;
  }
}
