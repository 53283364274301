import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../../hooks/react-redux";

import DialsContainerView from "../components/DialsContainerView";
import DialsEdit from "../../dials/containers/DialsEdit";
import Dials from "../../dials/containers/Dials";
import DialSkeleton from "../../dials/components/DialSkeleton";

import * as usersActions from "../../../actions/users/actions";

type DialsContainerProps = { appDrawerOpen: boolean };

const DialsContainer = ({
  appDrawerOpen,
}: DialsContainerProps): JSX.Element => {
  const [showDialForm, setShowDialForm] = useState(false);
  const [data, setData] = useState<Partial<User.Group>>({ dials: [] });

  const userId = useSelector((state) => state.users.user._id);
  const tabValue = useSelector((state) => state.global.tabValue || 0);

  const reduxData = useSelector((state) => state.users);

  const cardCount = useSelector((state) => state.users?.count);

  const groupId =
    useSelector((state) => state.users.user.groups[tabValue]?._id) || "";

  const dispatch = useDispatch();

  const updateOrder = (): void => {
    if (groupId) {
      const newGroup = {
        ...data,
        dials: data.dials?.map((dial, i) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { image, ...rest } = dial;
          return { ...rest, order: i };
        }),
      };

      dispatch(
        usersActions.updateOneGroupsRequest({
          userId,
          groupId,
          newGroup,
          withMessage: false,
        })
      );
    }
  };

  useEffect(() => {
    // Using localstate for DnD.
    setData(reduxData?.user.groups[tabValue]);
  }, [reduxData, tabValue]);

  const createDial: Dial.CreateDial = () => {
    setShowDialForm(true);
  };

  const moveDial = (dragIndex: number, hoverIndex: number): void => {
    if (data.dials) {
      const dragDial = data.dials[dragIndex];
      const newData = { ...data } as Required<Pick<User.Group, "dials">>;
      newData.dials.splice(dragIndex, 1);
      newData.dials.splice(hoverIndex, 0, dragDial);
      setData({ ...newData });
    }
  };

  const skeleton: React.ReactNode[] = [];
  const genSkeleton = (j: number): React.ReactNode => {
    for (let i = 0; i < j; i++) {
      skeleton.push(<DialSkeleton key={i} />);
    }
    return skeleton;
  };

  const dndActions = {
    moveDial,
    updateOrder,
  };
  return (
    <React.Fragment>
      <DialsContainerView
        tabValue={tabValue}
        appDrawerOpen={appDrawerOpen}
        onAdd={createDial}
      >
        {data && data.dials
          ? data.dials.map((dial, i) => (
              <Dials
                key={i}
                data={dial}
                index={i}
                groupId={groupId}
                userId={userId}
                actions={dndActions}
              />
            ))
          : genSkeleton(cardCount.dialsCount[0]?.count)}
      </DialsContainerView>

      <DialsEdit
        showForm={showDialForm}
        setShowForm={setShowDialForm}
        // @ts-ignore
        data={{}}
        groupId={groupId}
        userId={userId}
      />
    </React.Fragment>
  );
};

export default DialsContainer;
