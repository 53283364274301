// @ts-nocheck
import * as actionTypes from "./actionTypes";

// MESSAGES
export const showMessage = (
  payload: Global.ShowMessagePayload
): Global.ShowMessage => {
  return {
    type: actionTypes.SHOW_MESSAGE,
    payload,
  };
};

export const hideMessage = (): Global.HideMessage => {
  return {
    type: actionTypes.HIDE_MESSAGE,
  };
};

// LOADER
export const showLoader = (): Global.ShowLoader => {
  return {
    type: actionTypes.SHOW_LOADER,
  };
};

export const hideLoader = (): Global.HideLoader => {
  return {
    type: actionTypes.HIDE_LOADER,
  };
};

// ERRORS
export const hideError = (): Global.HideError => {
  return {
    type: actionTypes.HIDE_ERROR,
  };
};

// ALERT
export const showAlert = (
  payload: Global.ShowAlertPayload
): Global.ShowAlert => {
  return {
    type: actionTypes.SHOW_ALERT,
    payload,
  };
};

export const hideAlert = (): Global.HideAlert => {
  return {
    type: actionTypes.CANCEL_ALERT,
  };
};

export const confirmAlertDialog = (): Global.ConfirmAlertDialog => {
  return {
    type: actionTypes.CONFIRM_ALERT,
  };
};

export const setLargeScreen = (
  payload: Global.SetLargeScreenPayload
): Global.SetLargeScreen => {
  return {
    type: actionTypes.LARGE_SCREEN,
    payload,
  };
};

export const setTabValue = (
  payload: Global.SetTabValuePayload
): Global.SetTabValue => {
  return {
    type: actionTypes.SET_TABVALUE,
    payload,
  };
};

export const apiFailure = (
  payload: Global.ApiFailurePayload
): Global.ApiFailure => {
  if (payload.code === "ERR_NETWORK") {
    return {
      type: actionTypes.SET_OFFLINE_MODE,
    };
  } else {
    return {
      type: actionTypes.API_FAILURE,
      payload,
    };
  }
};

export const pingApi = (): Global.PingApi => {
  return {
    type: actionTypes.PING_API,
  };
};

export const setOfflineMode = (): Global.SetOfflineMode => {
  return {
    type: actionTypes.SET_OFFLINE_MODE,
  };
};

export const setOnlineMode = (): Global.SetOnlineMode => {
  return {
    type: actionTypes.SET_ONLINE_MODE,
  };
};
