import { myInstance } from "../services/axiosInstance";

const apiEndPoint = "/api/groups";

// export type FetchResponse = (User.Group | User.GroupWithoutImages)[];
export type FetchResponse = { data: User.MainData[] };
export type UpdateResponse = FixMeLater;
export type UpdateAllResponse = FixMeLater;

type FetchReturn = { response?: FetchResponse; error?: ApiError };
type UpdateReturn = { response?: UpdateResponse; error?: ApiError };
type UpdateAllReturn = {
  response?: UpdateAllResponse;
  error?: ApiError;
};

export const apiCalls = {
  findOne: async (data: Group.FetchRequestPayload): Promise<FetchReturn> => {
    try {
      const email = data.email;
      const groupId = data.groupId;
      const withLogos = data.withLogos;
      const response = await myInstance.get(
        `${apiEndPoint}/${email}/${groupId}/${withLogos}`
      );
      return { response };
    } catch (error) {
      return { error };
    }
  },

  updateOne: async (
    data: Group.UpdateRequestPayload
  ): Promise<UpdateReturn> => {
    try {
      const response = await myInstance.patch(apiEndPoint, data);
      return { response };
    } catch (error) {
      return { error };
    }
  },

  updateAllGroups: async (
    data: Group.UpdateAllRequestPayload
  ): Promise<UpdateAllReturn> => {
    const { userId, groups } = data;
    try {
      const response = await myInstance.put(apiEndPoint, { userId, groups });
      return { response };
    } catch (error) {
      return { error };
    }
  },
};
