import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Grid,
} from "@mui/material";

import { TransitionProps } from "@mui/material/transitions";

import Warning from "@mui/icons-material/Warning";

import { useTranslation } from "react-i18next";
import parseApiErrors from "../services/parseApiErrors";
import * as globalActions from "../actions/global/actions";
import { useDispatch, useSelector } from "../hooks/react-redux";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HandleError = (): JSX.Element => {
  const error = useSelector((state) => state.global.error);

  const dispatch = useDispatch();

  const hideError = (): void => {
    dispatch(globalActions.hideError());
  };

  const { t } = useTranslation();
  const message = parseApiErrors(error.error);

  return (
    <div>
      <Dialog
        open={error.showError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container>
            <Grid item xs={4}>
              <Warning color="error" style={{ fontSize: 60 }} />
            </Grid>
            <Grid item xs={8}>
              {`Aïe ! Erreur ${message.messageCode}`}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message.messageTitle} : {message.messageText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideError} color="primary" autoFocus>
            {t("Ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HandleError;
