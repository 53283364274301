import * as actionTypes from "./actionTypes";

export const login = (payload: Auth.LoginRequestPayload): Auth.LoginRequest => {
  return {
    type: actionTypes.LOGIN,
    payload,
  };
};

export const loginSuccess = (
  payload: Auth.LoginSuccessPayload
): Auth.LoginSuccess => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload,
  };
};

export const logout = (
  payload: Auth.LogoutRequestPayload
): Auth.LogoutRequest => {
  return {
    type: actionTypes.LOGOUT,
    payload,
  };
};
