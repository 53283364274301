import React from "react";
import { Grid, GridSize, Paper, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
}));

type AppCardProps = {
  children: React.ReactNode;
  cardTitle: string;
  xs: GridSize;
  sm: GridSize;
  md: GridSize;
  lg: GridSize;
  xl: GridSize;
};

const AppCard = React.forwardRef(
  (
    { children, cardTitle, xs, sm, md, lg, xl }: AppCardProps,
    ref: any
  ): JSX.Element => {
    const classes = useStyles();
    return (
      <Grid
        ref={ref}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              {cardTitle}
            </Typography>
            {children}
          </Paper>
        </Grid>
      </Grid>
    );
  }
);

AppCard.displayName = "AppCard";

export default AppCard;
