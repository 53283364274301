import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";
import { deepOrange, blue } from "@mui/material/colors";

type GetTheme = (mode?: "dark" | "light") => Theme;

export const getTheme: GetTheme = (mode = "light") => {
  let theme = createTheme({
    typography: {
      body2: {
        fontSize: "0.750rem",
        lineHeight: 1.5,
      },
      body1: {
        fontSize: "0.8125rem",
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
      },
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            primary: blue,
            secondary: {
              main: "#dddddd",
            },
          }
        : {
            background: { default: "#181818" },
            type: "dark",
            primary: deepOrange,
            secondary: {
              main: "#575757",
              light: "#dddddd",
            },
          }),
    },
  });

  theme = createTheme(theme, {
    components: {
      MuiAppBar: {
        root: {
          width: "unset",
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return theme;
};
