import i18n from "../i18n";
import moment from "moment";
import * as authActions from "../actions/auth/actions";
import { getFromStorage } from "../services/storage";

type SetDataInAuthReducerType = (dispatch: any) => void;

export const setDataInAuthReducer: SetDataInAuthReducerType = (dispatch) => {
  moment.locale(i18n.language);

  const accessToken = getFromStorage("accessToken");
  if (accessToken) {
    const userEmail = getFromStorage("email") as string;
    const refreshToken = getFromStorage("refreshToken") as string;
    const accessTokenExpireAt = getFromStorage("accessTokenExpireAt") as string;
    const payload = {
      email: userEmail,
      refreshToken,
      accessToken,
      accessTokenExpireAt,
    };
    payload && dispatch(authActions.loginSuccess(payload));
  }
};
