import { myInstance } from "../services/axiosInstance";

const apiEndPoint = "/api/dials";

export type CreateResponse = FixMeLater;
export type UpdateResponse = FixMeLater;
export type DeleteResponse = FixMeLater;

type CreateReturn = { response?: CreateResponse; error?: ApiError };
type UpdateReturn = { response?: UpdateResponse; error?: ApiError };
type DeleteReturn = { response?: DeleteResponse; error?: ApiError };

export const apiCalls = {
  updateOne(payload: Dial.UpdateRequestPayload): Promise<UpdateReturn> {
    return myInstance
      .patch(apiEndPoint, payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  deleteOne(payload: Dial.DeleteRequestPayload): Promise<DeleteReturn> {
    const { userId, groupId, dialId } = payload;
    return myInstance
      .delete(apiEndPoint + "/" + userId + "/" + groupId + "/" + dialId)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  createOne(payload: Dial.CreateRequestPayload): Promise<CreateReturn> {
    return myInstance
      .post(apiEndPoint, payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
