import React from "react";
import { Link } from "react-router-dom";

import { Button, ButtonProps, Tooltip, Grid, GridProps } from "@mui/material";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";

const PREFIX = "Buttons";

const StyledButton = styled(Button, {
  label: `${PREFIX}-button`,
})<ButtonProps>(({ theme }) => ({
  minWidth: "unset",
  color: theme.palette.text.primary,
}));

const StyledGrid = styled(Grid, {
  label: `${PREFIX}-grid`,
})<GridProps>(() => ({
  position: "relative",
  top: "0px",
  right: "0px",
  minWidth: "unset",
  zIndex: 1000,
}));

const Buttons = (): JSX.Element => {
  const { t } = useTranslation();
  const title = t("Logout");

  return (
    <StyledGrid container justifyContent="flex-end">
      <Tooltip title={title}>
        {/* @ts-ignore */}
        <StyledButton component={Link} color="secondary" to="/logout">
          <ExitToAppIcon></ExitToAppIcon>
        </StyledButton>
      </Tooltip>
    </StyledGrid>
  );
};

export default Buttons;
