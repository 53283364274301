import * as actionTypes from "./actionTypes";
import { setInStorage, clearAllStorage } from "../../services/storage";

const defaultState: Auth.State = {
  loginFailed: false,
  isAuth: false,
  email: undefined,
  auth: undefined,
};

export default function reducer(
  state = defaultState,
  action: Auth.Actions
): Auth.State {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      const { accessToken, refreshToken, email, accessTokenExpireAt } =
        action.payload;
      accessToken && setInStorage("accessToken", accessToken);
      accessTokenExpireAt &&
        setInStorage("accessTokenExpireAt", accessTokenExpireAt);
      refreshToken && setInStorage("refreshToken", refreshToken);
      email && setInStorage("email", email);
      return {
        ...state,
        loginFailed: false,
        isAuth: true,
        email: action.payload.email,
        auth: { ...action.payload },
      };
    }
    case actionTypes.LOGIN_FAILURE:
      clearAllStorage();
      return { ...state, loginFailed: true, isAuth: false };

    case actionTypes.LOGOUT_SUCCESS:
      clearAllStorage();
      return {
        loginFailed: false,
        isAuth: false,
      };

    case actionTypes.LOGOUT_FAILURE:
      return { ...state };

    default:
      return state;
  }
}
