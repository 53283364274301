export const CLEAR_ALL_USER = "CLEAR_ALL_USER";

// Dials
export const COUNT_DIALS_FAILURE = "COUNT_DIALS_FAILURE";
export const COUNT_DIALS_REQUEST = "COUNT_DIALS_REQUEST";
export const COUNT_DIALS_SUCCESS = "COUNT_DIALS_SUCCESS";

export const CREATE_DIAL_REQUEST = "CREATE_DIAL_REQUEST";
export const CREATE_DIAL_SUCCESS = "CREATE_DIAL_SUCCESS";

export const DELETE_DIAL_REQUEST = "DELETE_DIAL_REQUEST";
export const DELETE_DIAL_SUCCESS = "DELETE_DIAL_SUCCESS";

export const UPDATE_DIAL_REQUEST = "UPDATE_DIAL_REQUEST";
export const UPDATE_DIAL_SUCCESS = "UPDATE_DIAL_SUCCESS";

// Groups
export const FETCH_GROUP_REQUEST = "FETCH_GROUP_REQUEST";
export const FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS";

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";

export const UPDATE_ALLGROUPS_REQUEST = "UPDATE_ALLGROUPS_REQUEST";
export const UPDATE_ALLGROUPS_FAILURE = "UPDATE_ALLGROUPS_FAILURE";
export const UPDATE_ALLGROUPS_SUCCESS = "UPDATE_ALLGROUPS_SUCCESS";
export const FETCH_CURRENT_GROUP_SUCCESS = "FETCH_CURRENT_GROUP_SUCCESS";

// Users
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_WITH_LOGOS_SUCCESS = "FETCH_USER_WITH_LOGOS_SUCCESS";
export const FETCH_USER_WITHOUT_LOGOS_SUCCESS =
  "FETCH_USER_WITHOUT_LOGOS_SUCCESS";

export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
