import React from "react";
import { useSelector } from "./hooks/react-redux";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// import "./App.scss";

import AppAlertDialog from "./components/AppAlertDialog";
import AppMessage from "./components/AppMessage";
import HandleError from "./components/HandleError";
import PublicRoutes from "./scenes/routes/PublicRoutes";
import PrivateRoutes from "./scenes/routes/PrivateRoutes";
import InitApp from "./InitApp";
import Loader from "./components/Loader";
import { getTheme } from "./getTheme";

const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
  ? "dark"
  : "light";

const App = (): JSX.Element => {
  const mode = useSelector((state) =>
    ["dark", "light"].includes(state.preferences.theme)
      ? state.preferences.theme
      : systemTheme
  );

  const isAuth = useSelector((state) => state.auth.isAuth);

  const theme = React.useMemo(() => getTheme(mode), [mode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <Loader />
          <InitApp>
            <div>
              <CssBaseline />
              <Router>
                <div className="App">
                  {isAuth ? (
                    <PrivateRoutes />
                  ) : (
                    <PublicRoutes isAuth={isAuth} />
                  )}
                </div>
              </Router>
            </div>
          </InitApp>
          <AppAlertDialog />
          <AppMessage />
          <HandleError />
        </DndProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
