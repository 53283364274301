// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types, react/jsx-handler-names */
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useField } from "formik";

const AppSelect = (props) => {
  const { form, options, label, ...others } = props;

  const [field] = useField(props.field);

  const handleChange = (e, value) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      // getOptionValue={option => option.value}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth />
      )}
      {...field}
      onChange={handleChange}
      {...others}
    />
  );
};

export default AppSelect;
