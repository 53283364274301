// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    marginTop: "44px",
    marginLeft: "0px",
    width: "unset",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  tabPanelShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: 200,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, appDrawerOpen, ...other } = props;
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.tabPanel, {
        [classes.tabPanelShift]: appDrawerOpen,
      })}
      item
      xs={12}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {children}
    </Grid>
  );
}

export default TabPanel;
