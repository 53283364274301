import * as Yup from "yup";
import i18n from "../../i18n";

export const validationSchema = Yup.object({
  name: Yup.string().nullable().required(i18n.t("Please enter a title")),
  url: Yup.string()
    // .url(i18n.t("Please enter a valid URL"))
    .nullable()
    .required(i18n.t("Please enter a url")),
  "image.contentType": Yup.string()
    .nullable()
    .oneOf(
      ["image/png", "image/jpeg"],
      "This is not a suported format (png or jpg)."
    ),
});
