import React from "react";
import { useDispatch, useSelector } from "../../../hooks/react-redux";
import * as authActions from "../../../actions/auth/actions";
import { Redirect } from "react-router-dom";

const Logout = (): JSX.Element => {
  const auth = useSelector((state) => state.auth.auth);
  const data = {
    refreshToken: auth?.refreshToken,
    email: auth?.email,
  };

  const dispatch = useDispatch();

  dispatch(authActions.logout(data));

  return <Redirect to="/" />;
};

export default Logout;
