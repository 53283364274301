import React, { forwardRef } from "react";
import {
  Card,
  CardProps,
  CardActionArea,
  CardMedia,
  CardMediaProps,
  CardContent,
  CardContentProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { uint8ArrayToDataURL } from "../../../services/utils";

const PREFIX = "DialCard";

const StyledCard = styled(Card, {
  label: `${PREFIX}-card`,
})<CardProps>(({ theme }) => ({
  maxHeight: 160,
  width: "100%",
  "&:hover": {
    boxShadow: "0px 0px 0px 6px " + theme.palette.secondary.main,
  },
  marginTop: "-15px",
}));

const StyledCardMedia = styled(CardMedia, {
  label: `${PREFIX}-cardmedia`,
})<CardMediaProps>(({ theme }) => ({
  // ⚠️ object-fit is not supported by IE 11.
  objectFit: "contain",
  height: "108px",
  borderBottom: "0px",
  padding: "2px",
  backgroundColor: theme.palette.secondary.light,
  backgroundSize: "contain",
}));

const StyledCardContent = styled(CardContent, {
  label: `${PREFIX}-cardcontent`,
})<CardContentProps>(({ theme }) => ({
  padding: "12px 16px 12px 16px",
  backgroundColor: theme.palette.background.paper,
  height: "57px",
}));

const StyledTitle = styled(Typography, {
  label: `${PREFIX}-title`,
})<TypographyProps>(() => ({
  whiteSpace: "nowrap",
  lineHeight: "unset",
  margin: "0",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

const StyledSubTitle = styled(Typography, {
  label: `${PREFIX}-subtitle`,
})<TypographyProps>(() => ({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

type DialsCardProps = { data: User.Dial; actions: Dial.CardActions };

const DialsCard = forwardRef(
  (
    { data, actions }: DialsCardProps,
    ref: React.Ref<HTMLDivElement>
  ): JSX.Element => {
    return (
      <StyledCard ref={ref}>
        <CardActionArea onClick={actions.cardClick}>
          <StyledCardMedia
            image={uint8ArrayToDataURL(data.image) || "assets/img/logo.png"}
            // title={data.url}
          />
          <StyledCardContent onClick={actions.cardClick}>
            <StyledTitle gutterBottom variant="body1">
              {data.name}
            </StyledTitle>
            <StyledSubTitle variant="body2">{data.url}</StyledSubTitle>
          </StyledCardContent>
        </CardActionArea>
      </StyledCard>
    );
  }
);

DialsCard.displayName = "DialsCard";

export default DialsCard;
