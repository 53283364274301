import React from "react";
import { useSelector, useDispatch } from "../../../hooks/react-redux";
import { withRouter } from "react-router-dom";
import LoginForm from "../components/LoginForm";

import * as authActions from "../../../actions/auth/actions";

const Login = (): JSX.Element => {
  const loginFailed = useSelector((state) => state.auth.loginFailed);

  const dispatch = useDispatch();

  const login = (values: Auth.FormData): void => {
    const { email, password } = values;
    dispatch(authActions.login({ email, password }));
  };

  return (
    <React.Fragment>
      <LoginForm onSubmit={login} loginFailed={loginFailed} />
    </React.Fragment>
  );
};

export default withRouter(Login);
