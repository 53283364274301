import { myInstance } from "../services/axiosInstance";

const apiEndPoint = "/api/auth";

export type LoginResponse = FixMeLater;
export type LogoutResponse = FixMeLater;

type LoginReturn = { response?: LoginResponse; error?: ApiError };
type LogoutReturn = { response?: LogoutResponse; error?: ApiError };

export const apiCalls = {
  login(data: Auth.LoginRequestPayload): Promise<LoginReturn> {
    // check if username/password are Ok
    return myInstance
      .post(apiEndPoint, data)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  logout(data: Auth.LoginRequestPayload): Promise<LogoutReturn> {
    // delete refreshToken
    return myInstance
      .delete(`${apiEndPoint}/refreshtoken`, { data })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
