import React from "react";
import { useSelector, useDispatch } from "../hooks/react-redux";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { IconButton, IconButtonProps, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";

import * as globalActions from "../actions/global/actions";

const PREFIX = "AppMessage";

const StyledIconButton = styled(IconButton, {
  label: `${PREFIX}-iconbutton`,
})<IconButtonProps>(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

type AppMessageProps = { label?: string; value?: string };

const AppMessage = ({
  label: propsLabel,
  value: propsValue,
}: AppMessageProps): JSX.Element => {
  const message = useSelector((state) => state.global.message);

  const dispatch = useDispatch();
  const hideMessage = (): void => {
    dispatch(globalActions.hideMessage());
  };

  const { t } = useTranslation();

  const handleClose = (
    _event:
      | React.MouseEvent<HTMLButtonElement>
      | React.SyntheticEvent<any>
      | Event,
    reason?: SnackbarCloseReason
  ): any => {
    if (reason === "clickaway") {
      return;
    }
    hideMessage();
  };

  const label = propsLabel || t("The record");
  const value = propsValue ? ' "' + propsValue + '" ' : " ";
  const insertVerb = t("created");
  const deleteVerb = t("deleted");
  const updateVerb = t("updated");
  const verb = {
    insert: insertVerb,
    delete: deleteVerb,
    update: updateVerb,
  };

  const messageText =
    message &&
    message.messageType &&
    label + value + t("has been") + " " + verb[message.messageType];

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={message.showMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionComponent={Slide}
        disableWindowBlurListener={true}
        ContentProps={{
          "aria-describedby": "message-id",
          sx: { backgroundColor: "primary.main" },
        }}
        message={<span id="message-id">{messageText}</span>}
        action={[
          <StyledIconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </StyledIconButton>,
        ]}
      />
    </React.Fragment>
  );
};

export default AppMessage;
