import React from "react";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { uint8ArrayToDataURL } from "../../../services/utils";

const useStyles = makeStyles(() => ({
  account: {
    position: "relative",
    top: "-24px",
  },
  avatarContainer: {
    padding: "10px 10px 10px 10px",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    justifyContent: "center",
    display: "flex",
    color: "white",
  },
  avatar: {
    width: 40,
    height: 40,
  },
}));

type AccountProps = {
  user: Pick<User.UserData, "name" | "firstname" | "avatar">;
};

const Account = ({ user }: AccountProps): JSX.Element => {
  const classes = useStyles();

  const MyAvatar = (): JSX.Element => {
    const avatarExist = user?.avatar && user?.avatar.contentType;
    const initials =
      !avatarExist &&
      user?.firstname &&
      user?.firstname.substring(0, 1).toUpperCase() +
        user?.name?.substring(0, 1).toUpperCase();
    return (
      <Avatar
        className={classes.avatar}
        src={uint8ArrayToDataURL(user?.avatar) || ""}
      >
        {initials}
      </Avatar>
    );
  };

  return (
    <Grid container justifyContent="center" className={classes.account}>
      <Grid item xs={12} className={classes.avatarContainer}>
        <MyAvatar></MyAvatar>
      </Grid>
      <Grid item xs={12} className={classes.name}>
        <Typography variant="subtitle1">{user?.firstname}</Typography>
      </Grid>
    </Grid>
  );
};

export default Account;
