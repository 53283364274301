import { findIndex } from "lodash";

export const deleteDialSuccess = (
  state: User.MainData,
  payload: User.DeleteDialSuccessPayload
): User.Group[] => {
  const { groupId, dialId } = payload;
  const groups = [...state.user.groups];
  const groupIdx = findIndex(groups, ["_id", groupId]);
  const dialIdx = findIndex(groups[groupIdx].dials, ["_id", dialId]);

  groups[groupIdx].dials?.splice(dialIdx, 1);

  return groups;
};
