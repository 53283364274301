import { findIndex } from "lodash";

export const updateDialSuccess = (
  state: User.State,
  payload: User.UpdateDialSuccessPayload
): User.Group[] => {
  const { oldGroupId, newGroupId, dials } = payload;
  const groupHasChanged = oldGroupId !== newGroupId;
  const groups = [...state.user.groups];
  const oldGroupIdx = findIndex(groups, ["_id", oldGroupId]);
  const oldDialIdx = findIndex(groups[oldGroupIdx].dials, ["_id", dials._id]);

  if (groupHasChanged) {
    const newGroupIdx = findIndex(groups, ["_id", newGroupId]);
    // Add dials into his new group
    groups[newGroupIdx].dials?.push(dials);
    // Remove dials from  his old group
    groups[oldGroupIdx].dials?.splice(oldDialIdx, 1);
  } else {
    groups[oldGroupIdx].dials?.splice(oldDialIdx, 1, dials);
  }
  return groups;
};
