import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Clear from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";

import { useField } from "formik";

const useStyles = makeStyles(() => ({
  positionEnd: { width: "40px", marginLeft: "0px" },
}));

type AppInputProps = {
  form: any;
  isClearable: boolean;
  multiline: boolean;
  field: any;
  fullWidth: boolean;
};

const AppInput = ({
  form,
  isClearable,
  multiline,
  field: formikField,
  fullWidth = true,
  ...others
}: AppInputProps): JSX.Element => {
  const classes = useStyles();

  const [field, meta] = useField(formikField);

  const clearIcon = (): JSX.Element => (
    <InputAdornment position="end" className={classes.positionEnd}>
      <IconButton
        aria-label="Clear"
        onClick={() => {
          form.setFieldTouched(field.name, false);
          form.setFieldError(field.name, "");
          form.setFieldValue(field.name, "");
        }}
        size="large"
      >
        <Clear />
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      InputProps={{
        multiline,
        endAdornment: field.value && isClearable && clearIcon(),
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth={fullWidth}
      {...field}
      {...others}
    />
  );
};

export default AppInput;
