import React from "react";
import { Card, CardActionArea, CardContent, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    maxHeight: 160,
    width: "100%",
    marginTop: "-15px",
  },
  media: {
    height: "108px",
    borderBottom: "0px",
    padding: "2px",
    backgroundColor: theme.palette.secondary.light,
  },
  cardContentRoot: {
    padding: "12px 16px 12px 16px",
    backgroundColor: theme.palette.background.paper,
    height: "57px",
  },
}));

const DialsCard = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
      <Grid container spacing={0}>
        <Card className={classes.card}>
          <CardActionArea>
            <Skeleton variant="rectangular" className={classes.media} />
            <CardContent className={classes.cardContentRoot}>
              <Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />
              <Skeleton height={10} />{" "}
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DialsCard;
