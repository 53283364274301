export const mergeGroupsWithoutLogosAndCurrentGroups = (
  currentGroups: User.Group[] | undefined = [],
  newGroups: User.Group[]
): User.Group[] =>
  newGroups.map((newGroup) => {
    const currentGroup = currentGroups.find(
      (item) => item._id === newGroup._id
    );

    if (currentGroup && newGroup.dials) {
      return {
        ...newGroup,
        dials: [
          ...newGroup.dials.map((newDial) => {
            const currentDial = currentGroup.dials?.find(
              (item) => item._id === newDial._id
            );
            return currentDial
              ? { ...newDial, image: currentDial.image }
              : newDial;
          }),
        ],
      };
    } else {
      return newGroup;
    }
  });
