import React from "react";
import { List, Grid } from "@mui/material";

const GroupsView = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <List>{children}</List>
      </Grid>
    </Grid>
  );
};

export default GroupsView;
