import * as actionTypes from "./actionTypes";

export const fetchPrefRequest = (
  payload: Preference.FetchRequestPayload
): Preference.FetchRequest => {
  return {
    type: actionTypes.FETCH_PREF_REQUEST,
    payload: payload,
  };
};

export const fetchPrefSuccess = (
  payload: Preference.FetchSuccessPayload
): Preference.FetchSuccess => {
  return {
    type: actionTypes.FETCH_PREF_SUCCESS,
    payload: payload,
  };
};

export const fetchPrefFailure = (
  payload: Preference.FetchFailurePayload
): Preference.FetchFailure => {
  return {
    type: actionTypes.FETCH_PREF_FAILURE,
    payload: payload,
  };
};

export const updatePrefRequest = (
  payload: Preference.UpdateRequestPayload
): Preference.UpdateRequest => {
  return {
    type: actionTypes.UPDATE_PREF_REQUEST,
    payload: payload,
  };
};

export const updatePrefSuccess = (
  payload: Preference.UpdateSuccessPayload
): Preference.UpdateSuccess => {
  return {
    type: actionTypes.UPDATE_PREF_SUCCESS,
    payload: payload,
  };
};

export const updatePrefFailure = (
  payload: Preference.UpdateFailurePayload
): Preference.UpdateFailure => {
  return {
    type: actionTypes.UPDATE_PREF_FAILURE,
    payload: payload,
  };
};

export const clearAllPref = (): Preference.ClearAll => {
  return {
    type: actionTypes.CLEAR_ALL_PREF,
  };
};
