export const HIDE_ERROR = "HIDE_ERROR";

export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";

export const SHOW_ALERT = "SHOW_ALERT";
export const CANCEL_ALERT = "CANCEL_ALERT";
export const CONFIRM_ALERT = "CONFIRM_ALERT";

export const API_FAILURE = "API_FAILURE";

export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

export const LARGE_SCREEN = "LARGE_SCREEN";

export const SET_TABVALUE = "SET_TABVALUE";

export const SET_OFFLINE_MODE = "SET_OFFLINE_MODE";
export const SET_ONLINE_MODE = "SET_ONLINE_MODE";

export const PING_API = "PING_API";
