import React from "react";
import {
  Menu,
  MenuProps,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const PREFIX = "CustomMenu";

const StyledMenu = styled(Menu, {
  label: `${PREFIX}-menu`,
})<MenuProps>(({ theme }) => ({
  border: "1px solid " + theme.palette.secondary.light,
}));

type CustomMenuProps = {
  menuitems: Dial.MenuItem[];
  onClose: VoidFunction;
  anchorEl: any;
};

const CustomMenu = ({
  menuitems,
  onClose,
  anchorEl,
}: CustomMenuProps): JSX.Element => {
  return (
    <StyledMenu
      elevation={1}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      id="customized-menu"
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {menuitems.map((item, i) => (
        <MenuItem key={i} onClick={item.onClick}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

export default CustomMenu;
