import { all } from "redux-saga/effects";
import { sagaWatcher as usersSagaWatcher } from "./users/sagas";
import { sagaWatcher as authSagaWatcher } from "./auth/sagas";
import { sagaWatcher as prefsSagaWatcher } from "./preferences/sagas";
import { sagaWatcher as gloabalSagaWatcher } from "./global/sagas";

export default function* rootSagas(): Generator {
  yield all([
    usersSagaWatcher(),
    authSagaWatcher(),
    prefsSagaWatcher(),
    gloabalSagaWatcher(),
  ]);
}
