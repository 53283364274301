import { myInstance } from "../services/axiosInstance";

const apiEndPoint = "/api/users";

export type FetchResponse = {
  data: User.Fetched[];
};
export type CountResponse = {
  data: User.Count[];
};
export type UpdateResponse = FixMeLater;

type FetchReturn = { response?: FetchResponse; error?: ApiError };
type CountReturn = { response?: CountResponse; error?: ApiError };
type UpdateReturn = { response?: UpdateResponse; error?: ApiError };

export const apiCalls = {
  fetchByEmail: async (
    data: User.FetchRequestPayload
  ): Promise<FetchReturn> => {
    try {
      const { email, withLogos } = data;
      const response = await myInstance.get(
        `${apiEndPoint}/${email}/${withLogos}`
      );
      return { response };
    } catch (error) {
      return { error };
    }
  },

  fetchCountByEmail(data: User.CountRequestPayload): Promise<CountReturn> {
    const email = data.email;
    const groupId = data.groupId;
    return myInstance
      .get(`${apiEndPoint}/prefetch/${email}/${groupId}`)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  updateOne(data: User.UpdateRequestPayload): Promise<UpdateReturn> {
    return myInstance
      .patch(apiEndPoint, data)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
