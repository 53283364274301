import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "../../../hooks/react-redux";

import GroupsForm from "../components/GroupsForm";
import GroupsView from "../components/GroupsView";
import GroupItem from "./GroupItem";
import GroupsButtons from "../components/GroupsButtons";
import SettingsAppBar from "../../appBars/components/SettingsAppBar";

import { UPDATE_ALLGROUPS_REQUEST } from "../../../actions/users/actionTypes";

import { apiCalls } from "../../../apiCalls/getIdApiCalls";

import * as globalActions from "../../../actions/global/actions";
import { useTranslation } from "react-i18next";

const Groups = (): JSX.Element => {
  const [t] = useTranslation();

  const [selectedData, setSelectedData] = useState<{
    group: Partial<User.Group>;
    index?: number | undefined;
  }>({
    group: {},
    index: undefined,
  });
  const [data, setData] = useState<User.Group[]>([]);

  const reduxData = useSelector((state) => state.users.user);
  const userId = useSelector((state) => state.users.user._id);

  const initData = useCallback(() => {
    // Using local state for DnD.
    const newGroups = reduxData.groups.map((group) => {
      const newGroup = { ...group };
      // delete newGroup.dials;
      return newGroup;
    });
    setData(newGroups);
  }, [reduxData.groups]);

  useEffect(() => {
    initData();
  }, [initData]);

  const dispatch = useDispatch();

  const updateOne = (values: Group.FormData): void => {
    const newData = [...data];

    // @ts-ignore
    if (selectedData.index) newData[selectedData.index] = { ...values };
    setData([...newData]);
  };

  const createOne = async (values: Group.FormData): Promise<void> => {
    const newData = [...data];
    const { response } = await apiCalls.getId();
    newData.push({
      ...values,
      _id: response?.data || "",
      dials: [],
    });
    setData([...newData]);
  };

  const onDelete: Group.OnDelete = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData([...newData]);
  };

  const onSelect: Group.OnSelect = (values) => {
    setSelectedData(values);
  };

  const submitForm: Group.OnSubmit = (values, resetForm) => {
    values._id ? updateOne(values) : createOne(values);
    setSelectedData({ group: {} });
    resetForm();
  };

  const onValidate = (): void => {
    const newData = data.map((group, i) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dials, ...rest } = group;
      return { ...rest, order: i };
    });

    dispatch(
      globalActions.showAlert({
        alertType: "update",
        confirmAction: {
          actionTypes: UPDATE_ALLGROUPS_REQUEST,
          payload: { userId, groups: [...newData], withMessage: true },
        },
      })
    );
  };

  const moveGroup: Group.MoveGroup = useCallback(
    (dragIndex, hoverIndex) => {
      const dragGroup = data[dragIndex];
      const newData = [...data];
      newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, dragGroup);
      setData([...newData]);
    },
    [data]
  );

  const actions = {
    onSubmit: submitForm,
    onDelete: onDelete,
    onSelect: onSelect,
    moveGroup,
  };

  const title = t("Manage groups");

  return (
    <>
      <SettingsAppBar title={title} />
      <GroupsForm actions={actions} data={selectedData.group}></GroupsForm>
      <GroupsView>
        {data &&
          data.map((group, i) => (
            <GroupItem
              key={group._id}
              group={group}
              index={i}
              actions={actions}
            />
          ))}
      </GroupsView>
      <GroupsButtons onValidate={onValidate} onReset={initData} />
    </>
  );
};

export default Groups;
