import React from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

type GroupsButtonsProps = { onValidate: () => void; onReset: () => void };

const GroupsButtons = ({
  onValidate,
  onReset,
}: GroupsButtonsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={6} sm={3} md={2} xl={1}>
        <Button
          fullWidth
          onClick={onReset}
          variant="contained"
          color="secondary"
        >
          {t("Reset")}
        </Button>
      </Grid>
      <Grid item xs={6} sm={3} md={2} xl={1}>
        <Button
          fullWidth
          onClick={onValidate}
          variant="contained"
          color="primary"
        >
          {t("Submit")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default GroupsButtons;
