import * as actionTypes from "./actionTypes";
import { setInStorage } from "../../services/storage";

const defaultState: Preference.State = {
  theme: "light",
};

export default function reducer(
  state = {
    ...defaultState,
  },
  action: Preference.Actions
): Preference.State {
  switch (action.type) {
    //
    case actionTypes.FETCH_PREF_SUCCESS:
      setInStorage("theme", action.payload.theme);
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_PREF_SUCCESS:
      setInStorage("theme", action.payload.theme);
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.CLEAR_ALL_PREF:
      return { ...defaultState };

    default:
      return state;
  }
}
