import { myInstance } from "../services/axiosInstance";

const apiEndPoint = "/api/getid";

type GetIpResponse = { data: string };

type GetIdReturn = { response?: GetIpResponse; error?: ApiError };

export const apiCalls = {
  getId: async (): Promise<GetIdReturn> => {
    try {
      const response = await myInstance.get(apiEndPoint);
      return { response };
    } catch (error) {
      return { error };
    }
  },
};
