import { takeEvery, call, put, StrictEffect } from "redux-saga/effects";
import * as usersActionTypes from "./actionTypes";
import * as actions from "./actions";
import * as globalActions from "../global/actions";
import * as userApi from "../../apiCalls/usersApiCalls";
import * as groupApi from "../../apiCalls/groupsApiCalls";
import * as dialApi from "../../apiCalls/dialsApiCalls";

type FetchReturn = { response: userApi.FetchResponse; error: ApiError };
type CountReturn = { response: userApi.CountResponse; error: ApiError };
type UpdateReturn = {
  response: userApi.UpdateResponse;
  error: ApiError;
};

// Groups
type FetchGroupReturn = {
  response: groupApi.FetchResponse;
  error: ApiError;
};

type UpdateGroupReturn = {
  response: groupApi.UpdateResponse;
  error: ApiError;
};

type UpdateAllGroupReturn = {
  response: groupApi.UpdateAllResponse;
  error: ApiError;
};

// Dials
type CreateDialReturn = {
  response: dialApi.CreateResponse;
  error: ApiError;
};
type UpdateDialReturn = {
  response: dialApi.UpdateResponse;
  error: ApiError;
};
type DeleteDialReturn = {
  response: dialApi.DeleteResponse;
  error: ApiError;
};

function* fetchRequestSaga(
  action: User.FetchRequest
): Generator<StrictEffect, any, FetchReturn> {
  const { response, error } = yield call(
    userApi.apiCalls.fetchByEmail,
    action.payload
  );
  if (response) {
    action.payload.withLogos
      ? yield put(actions.fetchUserWithLogosSuccess(response.data))
      : yield put(actions.fetchUserWithoutLogosSuccess(response.data));
  } else {
    yield put(globalActions.apiFailure(error));
  }
}

function* countRequestSaga(
  action: User.CountRequest
): Generator<StrictEffect, any, CountReturn> {
  const { response, error } = yield call(
    userApi.apiCalls.fetchCountByEmail,
    action.payload
  );
  if (response) {
    yield put(actions.countDialsSuccess(response.data));
  } else {
    console.error(error.response);
    yield put(globalActions.apiFailure(error));
  }
}

function* updateRequestSaga(
  action: User.UpdateRequest
): Generator<StrictEffect, any, UpdateReturn> {
  yield put(globalActions.showLoader());
  const { response, error } = yield call(
    userApi.apiCalls.updateOne,
    action.payload
  );
  if (response) {
    const payload = action.payload;
    yield put(globalActions.showMessage({ messageType: "update" }));
    yield put(actions.updateUserSuccess(payload));
    yield put(globalActions.hideLoader());
  } else {
    console.error(error.response);
    yield put(globalActions.hideLoader());
    yield put(globalActions.apiFailure(error));
  }
}

// =============== Groups

function* fetchGroupRequestSaga(
  action: Group.FetchRequest
): Generator<StrictEffect, any, FetchGroupReturn> {
  const { response, error } = yield call(
    groupApi.apiCalls.findOne,
    action.payload
  );
  if (response) {
    yield put(actions.fetchCurrentGroupSuccess(response.data[0]));
  } else {
    console.error(error.response);
    yield put(globalActions.apiFailure(error));
  }
}

function* updateOneGroupsSaga(
  action: Group.UpdateRequest
): Generator<StrictEffect, any, UpdateGroupReturn> {
  // yield put(globalActions.confirmAlertDialog());
  const { response, error } = yield call(
    groupApi.apiCalls.updateOne,
    action.payload
  );
  if (response) {
    yield put(actions.updateOneGroupsSuccess(action.payload));
    yield put(globalActions.setOnlineMode());
    yield put(globalActions.hideAlert());
  } else {
    console.error(error.response);
    yield put(globalActions.hideAlert());
    yield put(globalActions.apiFailure(error));
  }
}

function* updateAllGroupsSaga(
  action: Group.UpdateAllRequest
): Generator<StrictEffect, any, UpdateAllGroupReturn> {
  // yield put(globalActions.confirmAlertDialog());
  const { response, error } = yield call(
    groupApi.apiCalls.updateAllGroups,
    action.payload
  );
  if (response) {
    if (action.payload.withMessage)
      yield put(globalActions.showMessage({ messageType: "update" }));
    yield put(actions.updateAllGroupsSuccess(action.payload));
    yield put(globalActions.setOnlineMode());
    yield put(globalActions.hideAlert());
  } else {
    console.error(error.response);
    // yield put(globalActions.hideLoader());
    yield put(globalActions.hideAlert());
    yield put(globalActions.apiFailure(error));
  }
}

// ================== Dials

function* updateDialSaga(
  action: Dial.UpdateRequest
): Generator<StrictEffect, any, UpdateDialReturn> {
  const { response, error } = yield call(
    dialApi.apiCalls.updateOne,
    action.payload
  );
  if (response) {
    yield put(globalActions.showMessage({ messageType: "update" }));
    yield put({
      type: usersActionTypes.UPDATE_DIAL_SUCCESS,
      payload: action.payload,
    });
  } else {
    console.error(error.response);
    yield put(globalActions.apiFailure(error));
  }
}

function* deleteDialSaga(
  action: Dial.DeleteRequest
): Generator<StrictEffect, any, DeleteDialReturn> {
  yield put(globalActions.confirmAlertDialog());
  const { response, error } = yield call(
    dialApi.apiCalls.deleteOne,
    action.payload
  );
  if (response) {
    const payload = response.data.data;
    yield put(globalActions.showMessage({ messageType: "delete" }));
    yield put({
      type: usersActionTypes.DELETE_DIAL_SUCCESS,
      payload: payload,
    });
  } else {
    console.error(error.response);
    yield put(globalActions.apiFailure(error));
  }
}

function* createDialSaga(
  action: Dial.CreateRequest
): Generator<StrictEffect, any, CreateDialReturn> {
  const { response, error } = yield call(
    dialApi.apiCalls.createOne,
    action.payload
  );
  if (response) {
    const payload = { ...action.payload, dial: response.data };
    yield put(globalActions.showMessage({ messageType: "insert" }));
    yield put({
      type: usersActionTypes.CREATE_DIAL_SUCCESS,
      payload: payload,
    });
  } else {
    console.error(error.response);
    yield put(globalActions.apiFailure(error));
  }
}

export function* sagaWatcher(): Generator {
  yield takeEvery(usersActionTypes.FETCH_USER_REQUEST, fetchRequestSaga);
  yield takeEvery(usersActionTypes.COUNT_DIALS_REQUEST, countRequestSaga);
  yield takeEvery(usersActionTypes.UPDATE_USER_REQUEST, updateRequestSaga);
  yield takeEvery(usersActionTypes.FETCH_GROUP_REQUEST, fetchGroupRequestSaga);
  yield takeEvery(usersActionTypes.UPDATE_GROUP_REQUEST, updateOneGroupsSaga);
  yield takeEvery(
    usersActionTypes.UPDATE_ALLGROUPS_REQUEST,
    updateAllGroupsSaga
  );
  yield takeEvery(usersActionTypes.UPDATE_DIAL_REQUEST, updateDialSaga);
  yield takeEvery(usersActionTypes.DELETE_DIAL_REQUEST, deleteDialSaga);
  yield takeEvery(usersActionTypes.CREATE_DIAL_REQUEST, createDialSaga);
}
