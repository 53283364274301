import { myInstance } from "../services/axiosInstance";

const apiEndPoint = "/api/";

export type PingResponse = {
  data: {
    message: "Hello world!";
    version: string;
    userCount: number;
  };
};

type PingReturn = { response?: PingResponse; error?: ApiError };

export const apiCalls = {
  ping: async (): Promise<PingReturn> => {
    try {
      const response = await myInstance.get(apiEndPoint);
      return { response };
    } catch (error) {
      return { error };
    }
  },
};
