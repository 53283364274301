import React from "react";
import { useDispatch, useSelector } from "../../../hooks/react-redux";

import DialForm from "../components/DialForm";
import * as usersActions from "../../../actions/users/actions";

type DialsEditProps = {
  groupId: string;
  userId: string;
  data: User.Dial;
  showForm: boolean;
  setShowForm: (v: boolean) => void;
};

const DialsEdit = ({
  data,
  userId,
  groupId,
  showForm,
  setShowForm,
}: DialsEditProps): JSX.Element => {
  const groupsList = useSelector((state) => state.users.groupsList);
  const nextOrder = useSelector(
    (state) =>
      state.users.user.groups.find((group) => group._id === groupId)?.dials
        ?.length
  );

  const dispatch = useDispatch();

  const updateOne = (values: Dial.FormData): void => {
    const oldGroupId = groupId;
    const newGroupId = values.groupId?.value;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { groupId: toIgnore, ...newValues } = values;
    const payload = {
      userId,
      oldGroupId,
      newGroupId,
      dials: { ...newValues },
    };
    dispatch(usersActions.updateDialRequest(payload));
  };

  const createOne = (values: Dial.FormData): void => {
    const payload = {
      userId: userId,
      groupId: values.groupId?.value,
      dial: values,
    };
    dispatch(usersActions.createDialRequest(payload));
  };

  const submitForm: Dial.SubmitForm = (values) => {
    data._id ? updateOne({ _id: data._id, ...values }) : createOne(values);
    setShowForm(false);
  };

  const onCancel: Dial.OnCancel = () => {
    setShowForm(false);
  };

  const actions: Dial.FormActions = {
    onSubmit: submitForm,
    onCancel: onCancel,
  };

  return (
    <DialForm
      showForm={showForm}
      actions={actions}
      data={data}
      groupsList={groupsList}
      groupId={groupId}
      nextOrder={nextOrder}
    />
  );
};

export default DialsEdit;
