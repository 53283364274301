import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import makeStyles from "@mui/styles/makeStyles";
import { Formik, Field, Form } from "formik";

import AppInput from "../../../components/form/AppInput";
import { validationSchema } from "../validationSchema";

const useStyles = makeStyles(() => ({
  root: {
    padding: "70px 10px 8px 10px",
  },
  button: { marginTop: "8px" },
  input: { width: "calc(100% - 62px)" },
}));

type GroupsFormProps = {
  data: Partial<User.Group>;
  actions: Group.FormActions;
};

const GroupsForm = ({ data, actions }: GroupsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const initialValues = {
    name: data?.name || "",
    _id: data?._id || undefined,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        actions.onSubmit(values, resetForm);
      }}
    >
      <Form>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className={classes.root}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            style={{ paddingLeft: "24px" }}
          >
            <Field
              className={classes.input}
              component={AppInput}
              label={t("Group Name")}
              name="name"
              type="text"
              placeholder=""
              fullWidth={false}
              isClearable={true}
            />
            <IconButton className={classes.button} type="submit" size="large">
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default GroupsForm;
