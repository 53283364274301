import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";

import DialCard from "../components/DialCard";
import DialsEdit from "./DialsEdit";
import DialMenu from "../components/DialMenu";

import { DELETE_DIAL_REQUEST } from "../../../actions/users/actionTypes";
import * as globalActions from "../../../actions/global/actions";
import { DndItemTypes } from "../../DndItemTypes";

type DialsProps = {
  data: User.Dial;
  groupId: string;
  userId: string;
  index: number;
  actions: Dial.DnDActions;
};

const Dials = ({
  data,
  groupId,
  userId,
  index,
  actions: dndActions,
}: DialsProps): JSX.Element => {
  const [isOver, setIsOver] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu: Dial.OpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu: Dial.CloseMenu = () => {
    setAnchorEl(null);
  };

  const onEdit: Dial.OnEdit = () => {
    setIsOver(false);
    closeMenu();
    setShowForm(true);
  };

  const cardClick: Dial.CardClick = () => {
    window.open(data.url, "_blank");
  };

  const dispatch = useDispatch();

  const onDelete: Dial.OnDelete = () => {
    dispatch(
      globalActions.showAlert({
        alertType: "delete",
        confirmAction: {
          actionTypes: DELETE_DIAL_REQUEST,
          payload: {
            userId: userId,
            groupId: groupId,
            dialId: data._id,
          },
        },
      })
    );
    closeMenu();
    setIsOver(false);
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: DndItemTypes.DIAL,
    hover(item) {
      if (!ref.current) {
        return;
      }
      // @ts-ignore
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      dndActions.moveDial(dragIndex, hoverIndex);
      // @ts-ignore
      item.index = hoverIndex;
    },
    drop: () => ({ hoverIndex: index }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: DndItemTypes.DIAL,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        dndActions.updateOrder();
      }
    },
  });

  drag(drop(ref));

  const menuItems: Dial.MenuItem[] = [
    { label: "Edit", icon: <EditIcon></EditIcon>, onClick: onEdit },
    { label: "Delete", icon: <DeleteIcon></DeleteIcon>, onClick: onDelete },
  ];

  const actions: Dial.CardActions = {
    onEdit: onEdit,
    onDelete: onDelete,
    cardClick: cardClick,
    openMenu: openMenu,
    closeMenu: closeMenu,
    ...dndActions,
  };

  return (
    <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
      <Grid
        onMouseOver={() => setIsOver(true)}
        onMouseOut={() => setIsOver(false)}
        container
        spacing={0}
      >
        <DialMenu
          actions={actions}
          isOver={isOver}
          // @ts-ignore
          isDragging={isDragging}
          anchorEl={anchorEl}
          menuitems={menuItems}
        />
        <DialCard
          ref={ref}
          // anchorEl={anchorEl}
          actions={actions}
          data={data}
          // menuitems={menuItems}
        />
      </Grid>
      {showForm && (
        <DialsEdit
          showForm={showForm}
          setShowForm={setShowForm}
          data={data}
          groupId={groupId}
          userId={userId}
        />
      )}
    </Grid>
  );
};

export default Dials;
