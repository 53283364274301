import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  FormHelperText,
  CardMedia,
  IconButton,
  Theme,
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import { useField } from "formik";

import { uint8ArrayToDataURL, fileUpload } from "../../services/utils";

const useStyles = makeStyles<Theme>((theme) => ({
  input: {
    display: "none",
  },
  imageSrcRounded: {
    // position: "absolute",
    padding: "2px",
    borderRadius: "129px",
    width: "150px",
    height: "150px",
  },
  imageGrid: {
    display: "flex",
    justifyContent: "center",
    height: "calc(160px + " + theme.spacing(2) + "px)",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  imageError: {
    textAling: "center",
  },
}));

type AppButtonUploadImageProps = { form: any; field: any };

const AppButtonUploadImage = ({
  form,
  field: FormikField,
}: AppButtonUploadImageProps): JSX.Element => {
  const classes = useStyles();

  const [field] = useField(FormikField);

  // const fileUpload = ({target}:{target:FileEventTarget}) => {

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.imageGrid}>
          {uint8ArrayToDataURL(field.value) && (
            <CardMedia
              className={classes.imageSrcRounded}
              image={uint8ArrayToDataURL(field.value) || " "}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.toolbar}>
          <label htmlFor="raised-button-file">
            <IconButton focusRipple component="span" size="large">
              <input
                accept="image/*"
                className={classes.input}
                type="file"
                id="raised-button-file"
                // @ts-ignore
                onChange={(files) => fileUpload({ files, field, form })}
              />
              <PublishIcon />
            </IconButton>
          </label>
          <IconButton
            focusRipple
            component="span"
            className={classes.image}
            onClick={() => {
              form.setFieldTouched(field.name, false);
              form.setFieldError(field.name, "");
              form.setFieldValue(field.name, { contentType: "", data: "" });
            }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <FormHelperText
        className={classes.imageError}
        error={form.status && Boolean(form.status.imageFormatError)}
      >
        {form.status && form.status.imageFormatError}
      </FormHelperText>
    </>
  );
};

export default AppButtonUploadImage;
