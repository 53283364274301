import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import AppSpeedDial from "../../../components/AppSpeedDial";
import TabPanel from "../../../components/TabPanel";

const useStyles = makeStyles(() => ({
  root: {
    margin: "unset",
    marginTop: "10px",
    width: "100%",
  },
}));

type DialsContainerViewProps = {
  children: React.ReactNode;
  tabValue: number;
  appDrawerOpen: boolean;
  onAdd: Dial.CreateDial;
};

const DialsContainerView = ({
  children,
  tabValue,
  appDrawerOpen,
  onAdd,
}: DialsContainerViewProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TabPanel appDrawerOpen={appDrawerOpen} value={tabValue} index={tabValue}>
      <Grid container sx={{ padding: 0 }} spacing={4} className={classes.root}>
        {children}
      </Grid>
      <AppSpeedDial onAdd={onAdd}></AppSpeedDial>
    </TabPanel>
  );
};

export default DialsContainerView;
