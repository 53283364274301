// @ts-nocheck
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

type AppAutoCompleteProps = {
  name: string;
  options: Option[];
  value: Option | undefined | string;
  label?: string;
  onChange: (event: any, value: any) => void;
};
const AppAutoComplete = ({
  options,
  label,
  name,
  value,
  onChange,
  ...others
}: AppAutoCompleteProps): JSX.Element => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      name={name}
      value={value}
      onChange={onChange}
      // getOptionValue={option => option.value}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth />
      )}
      {...others}
    />
  );
};

export default AppAutoComplete;
