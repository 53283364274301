import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "../../../hooks/react-redux";
import { AppBar, Toolbar, Grid, Theme, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { pingApi } from "../../../actions/global/actions";
import { updatePrefRequest } from "../../../actions/preferences/actions";
import { ThemeSwitch } from "./ThemeSwitch";
import { Title } from "./Title";
import { OfflineIndicator } from "./OfflineIndicator";
import { DrawerButton } from "./DrawerButton";

const fetchInterval = 60000;

const drawerWidth = 220;

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: { padding: "10px" },
}));

type MainAppBarProps = {
  toggleDrawer: (v: boolean) => void;
  appDrawerOpen: boolean;
  groups: User.Group[];
  tabValue: number;
};

const MainAppBar = ({
  toggleDrawer,
  appDrawerOpen,
  groups,
  tabValue = 0,
}: MainAppBarProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const offlineMode = useSelector((state) => state.global.offlineMode);
  const email = useSelector((state) => state.users.user.email);

  const [themeMode, setThemeMode] = useState<Preference.ThemeMode>(
    theme.palette.mode || "light"
  );

  const dispatch = useDispatch();
  // If offlineMode then check network every interval
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (offlineMode) dispatch(pingApi());
    }, fetchInterval);

    return () => clearInterval(interval);
  }, [dispatch, offlineMode]);

  const setTheme = useCallback(
    (newTheme: Preference.ThemeMode) => {
      setThemeMode(newTheme);
      const payload = { email, theme: newTheme };
      dispatch(updatePrefRequest(payload));
    },
    [dispatch, email]
  );

  const onThemeChange = (): void => {
    theme.palette.mode === "dark" ? setTheme("light") : setTheme("dark");
  };

  const title = groups && groups[tabValue] ? groups[tabValue].name : "";

  return (
    <AppBar
      position="fixed"
      color="secondary"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: appDrawerOpen,
      })}
    >
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Grid item>
            <Grid container justifyContent="flex-start" alignItems={"center"}>
              <Grid item>
                <DrawerButton
                  toggleDrawer={toggleDrawer}
                  appDrawerOpen={appDrawerOpen}
                />
              </Grid>
              <Grid item>
                <Title title={title} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  {offlineMode && <OfflineIndicator />}
                </Grid>
              </Grid>
              <Grid item>
                <ThemeSwitch onThemeChange={onThemeChange} mode={themeMode} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default MainAppBar;
