import React, { forwardRef } from "react";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  item: {
    border: (isDragging) => isDragging && "1px dashed gray",
    cursor: "move",
    opacity: (isDragging) => (isDragging ? 0.5 : 1),
  },
}));

type GroupItemViewProps = {
  group: User.Group;
  actions: Group.FormActions;
  index: number;
  isDragging: boolean;
};
const GroupItemView = forwardRef(
  ({ group, actions, index, isDragging }: GroupItemViewProps, ref: any) => {
    const classes = useStyles(isDragging);

    return (
      <ListItem ref={ref} className={classes.item} button>
        <ListItemText id={group._id} primary={group.name} />
        <ListItemSecondaryAction>
          <IconButton
            color="secondary"
            onClick={() => actions.onSelect({ index, group })}
            size="large"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => actions.onDelete(index)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
);

GroupItemView.displayName = "GroupItemView";

export default GroupItemView;
