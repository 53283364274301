import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "./hooks/react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/nl";

import {
  fetchAllGroupsWithoutLogos,
  fetchAllGroupsWithLogos,
  fetchPreferences,
  setLargeScreen,
  setDataInAuthReducer,
} from "./utils";

type InitAppProps = { children: React.ReactNode };

const InitApp = ({ children }: InitAppProps): JSX.Element => {
  const matches = useMediaQuery(
    useCallback(() => "(min-width:960px)", []),
    { defaultMatches: true }
  );

  const isLargeScreen = useSelector((state) => state.global.largeScreen);
  const email = useSelector((state) => state.auth.email);
  const groupsList = useSelector((state) => state.users.groupsList);

  const dispatch = useDispatch();

  useEffect(() => {
    isLargeScreen !== matches && setLargeScreen(dispatch, matches);
  }, [dispatch, isLargeScreen, matches]);

  /* 
    Dès que l'email est connu, on récupère :
      - Les préférences de l'utilisateurs,
      - Les données des groupes SANS les logos
  */
  useEffect(() => {
    email && fetchPreferences(dispatch, email);
    email && fetchAllGroupsWithoutLogos(dispatch, email);
  }, [dispatch, email]);

  /* 
    Dès que l'email est connu et qu'on a récupéré la list des groupes, on récupère :
      - Les données des groupes AVEC les logos
      NB : On fait une requete pour chaque group pour paralléliser et réduire le volume individuel de chaque requete http
  */
  useEffect(() => {
    email &&
      groupsList.length > 0 &&
      fetchAllGroupsWithLogos(dispatch, email, groupsList);
  }, [dispatch, email, groupsList]);

  /* 
    Au 1er lancement de l'application on récupère des données d'authtification du stockage local pour les enregistrer dans le store
  */
  useEffect(() => {
    setDataInAuthReducer(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default InitApp;
