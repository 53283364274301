import { takeEvery, call, put, StrictEffect } from "redux-saga/effects";
import * as authActionTypes from "./actionTypes";
import * as globalActionTypes from "../global/actionTypes";
import * as authApi from "../../apiCalls/authApiCalls";

type LoginReturn = { response: authApi.LoginResponse; error: ApiError };
type LogoutReturn = { response: authApi.LogoutResponse; error: ApiError };

function* loginSaga(
  action: Auth.LoginRequest
): Generator<StrictEffect, any, LoginReturn> {
  yield put({ type: globalActionTypes.SHOW_LOADER });
  const { response, error } = yield call(
    authApi.apiCalls.login,
    action.payload
  );
  if (response) {
    // dispatch a success action to the store with the new data
    const payload = response.data;
    yield put({ type: authActionTypes.LOGIN_SUCCESS, payload });
    yield put({ type: globalActionTypes.HIDE_LOADER });
  } else {
    if (error.response.status === 401) {
      yield put({ type: authActionTypes.LOGIN_FAILURE });
      yield put({ type: globalActionTypes.HIDE_LOADER });
    } else {
      console.error(error.response);
      // dispatch a failure action to the store with the error
      yield put({ type: globalActionTypes.API_FAILURE, error: error.response });
    }
  }
}

function* logoutSaga(
  action: Auth.LogoutRequest
): Generator<StrictEffect, any, LogoutReturn> {
  const { response, error } = yield call(
    authApi.apiCalls.logout,
    action.payload
  );
  if (response) {
    // dispatch a success action to the store with the new data
    if (response.data.errors) {
      yield put({ type: authActionTypes.LOGOUT_FAILURE });
    } else {
      const payload = response.data.email;
      yield put({ type: authActionTypes.LOGOUT_SUCCESS, payload });
    }
  } else {
    console.error(error.response);
    // dispatch a failure action to the store with the error
    yield put({ type: globalActionTypes.API_FAILURE, error: error.response });
  }
}

export function* sagaWatcher(): Generator {
  yield takeEvery(authActionTypes.LOGIN, loginSaga);
  yield takeEvery(authActionTypes.LOGOUT, logoutSaga);
}
