import React from "react";
import { useSelector, useDispatch } from "../../../hooks/react-redux";
import UsersForm from "../components/UsersForm";
import SettingsAppBar from "../../appBars/components/SettingsAppBar";
import * as usersActions from "../../../actions/users/actions";
import { validationShema } from "../validationShema";
import { useTranslation } from "react-i18next";

const Users = (): JSX.Element => {
  const [t] = useTranslation();
  const user = useSelector((state) => state.users.user);

  const dispatch = useDispatch();

  const updateOne = (values: User.FormData): void => {
    dispatch(usersActions.updateUserRequest(values));
  };

  const submitForm = (values: User.FormData): void => {
    // Never record Password Confirmation
    delete values.passwordC;
    // In case of update if password = "" do not update it
    Boolean(user._id) && values.password === "" && delete values.password;

    // user._id ? updateOne(values) : createOne(values);
    updateOne(values);
  };

  const actions = {
    onSubmit: submitForm,
  };

  const title = t("My account");

  return (
    <>
      <SettingsAppBar title={title} />
      <UsersForm
        actions={actions}
        user={user}
        validationSchema={validationShema}
      />
    </>
  );
};

export default Users;
