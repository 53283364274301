import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Formik, Field, Form } from "formik";

import AppCard from "../../../components/AppCard";
import AppInput from "../../../components/form/AppInput";
import AppSelect from "../../../components/form/AppSelect";
import ImageInput from "../../../components/form/ImageInput";
import { getObjectFromValue } from "../../../services/utils";
import { validationSchema } from "../validationSchema";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: "8px 20px 8px 20px",
  },
  input: {
    display: "none",
  },
  image: {
    width: "300px",
    margin: "auto",
    // color: theme.palette.text.primary,
    position: "relative",
    height: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      // height: 100
    },
    "&:hover": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  imageSrc: {
    position: "absolute",
    maxHeight: "100%",
    maxWidth: "100%",
    padding: "2px",
  },
  imageGrid: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
  },
  imageBackdrop: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: ".5",
    maxWidth: "300px",
    margin: "auto",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)} ${theme.spacing(4)} ${
      theme.spacing(1) + 6
    }px`,
  },
}));

type DialFormProps = {
  data: User.Dial;
  actions: Dial.FormActions;
  showForm: boolean;
  groupId: string;
  groupsList: User.GroupList[];
  nextOrder: number | undefined;
};

const DialForm = ({
  data,
  actions,
  showForm,
  groupId,
  groupsList,
  nextOrder = 0,
}: DialFormProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const initialValues: Partial<Dial.FormData> = {
    name: data.name || "",
    url: data.url || "",
    groupId: getObjectFromValue(groupId, groupsList) || undefined,
    image: data.image || { contentType: "" },
    order: nextOrder,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        actions.onSubmit(values);
      }}
    >
      <Modal open={showForm} className={classes.root}>
        {/* @ts-ignore */}
        <AppCard elevation={0} xs={12} sm={8} md={6} lg={5} xl={4}>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Field
                  component={AppInput}
                  label={t("Dial name")}
                  name="name"
                  type="text"
                  placeholder=""
                  autoFocus
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={AppSelect}
                  label={t("Group name")}
                  name="groupId"
                  options={groupsList}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={AppInput}
                  label={t("URL")}
                  name="url"
                  type="text"
                  isClearable={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.imageGrid}>
                <Field
                  component={ImageInput}
                  name="image"
                  actions={actions}
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={actions.onCancel}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </AppCard>
      </Modal>
    </Formik>
  );
};

export default DialForm;
