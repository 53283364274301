import * as Yup from "yup";
import i18n from "../../i18n";

export const validationShema = (): any => {
  return Yup.object({
    _id: Yup.string().nullable(),
    name: Yup.string().nullable().required(i18n.t("Please enter a lastname")),
    firstname: Yup.string()
      .nullable()
      .required(i18n.t("Please enter a firstname")),
    email: Yup.string()
      .nullable()
      .email()
      .required(i18n.t("Please enter an email")),
    password: Yup.string()
      .nullable()
      .when("_id", {
        // Update case
        is: (_id: string) => {
          return Boolean(_id) === true;
        },
        then: Yup.string().notRequired(),
      })
      .when("_id", {
        // Create case
        is: (_id: string) => {
          return Boolean(_id) === false;
        },
        then: Yup.string().required(i18n.t("Please enter a password")),
      })
      .min(6, i18n.t("Password has to be longer than 6 characters!")),
    passwordC: Yup.string()
      .nullable()
      .when("password", {
        is: (password: string) => {
          return Boolean(password);
        },
        then: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords are not the same!")
          .required("Password confirmation is required!"),
      }),
    "avatar.contentType": Yup.string()
      .nullable()
      .oneOf(
        ["image/png", "image/jpeg"],
        "This is not a suported format (png or jpg)."
      ),
  });
};
