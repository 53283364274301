import React from "react";
import { Fade, Fab, Grid } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import makeStyles from "@mui/styles/makeStyles";

import CustomMenu from "../../../components/CustomMenu";
import { isTouchDevice } from "../../../services/utils";

const useStyles = makeStyles(() => ({
  buttonBar: {
    zIndex: 1000,
    marginTop: "-30px",
  },
  buttonMore: {
    marginRight: "-15px",
    width: "30px",
    height: "30px",
    minHeight: "unset",
  },
}));

type DialMenuProps = {
  actions: Pick<Dial.CardActions, "openMenu" | "closeMenu">;
  isOver: boolean;
  isDragging: boolean;
  anchorEl: any;
  menuitems: Dial.MenuItem[];
};

const DialMenu = ({
  actions,
  isOver,
  isDragging,
  anchorEl,
  menuitems,
}: DialMenuProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Fade
      in={(isOver || Boolean(anchorEl) || isTouchDevice()) && !isDragging}
      style={{ transitionDelay: isOver && !isDragging ? "200ms" : "0ms" }}
      {...(isOver && !isDragging ? { timeout: 500 } : {})}
    >
      <Grid container justifyContent="flex-end" className={classes.buttonBar}>
        <Fab
          variant="circular"
          size="small"
          onClick={actions.openMenu}
          className={classes.buttonMore}
        >
          <MoreIcon />
        </Fab>
        <CustomMenu
          anchorEl={anchorEl}
          menuitems={menuitems}
          onClose={actions.closeMenu}
          // @ts-ignore
          style={{ marginLeft: "-8px", marginTop: "-10px" }}
        />
      </Grid>
    </Fade>
  );
};
export default DialMenu;
