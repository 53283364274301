import React from "react";
import { Fab, FabProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";

const PREFIX = "AppSpeedDial";

type FabType = FabProps;

const StyledFab = styled(Fab, {
  label: `${PREFIX}-fab`,
})<FabType>(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const AppSpeedDial = ({ onAdd }: { onAdd: VoidFunction }): JSX.Element => {
  return (
    <StyledFab
      color="primary"
      aria-label="add"
      onClick={() => {
        onAdd();
      }}
    >
      <AddIcon />
    </StyledFab>
  );
};

export default AppSpeedDial;
