import i18n from "../../i18n";
import { Buffer } from "buffer";

type FileUpload = (v: {
  files: any;
  form: Record<string, any>;
  field: Record<string, any>;
}) => any;

export const fileUpload: FileUpload = ({ files, field, form }) => {
  const file = files.target.files[0];

  if (file.type.split("/")[0] === "image") {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        const max_size = 300;
        let w = image.width;
        let h = image.height;

        if (w > h) {
          if (w > max_size) {
            h *= max_size / w;
            w = max_size;
          }
        } else {
          if (h > max_size) {
            w *= max_size / h;
            h = max_size;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        canvas.getContext("2d")?.drawImage(image, 0, 0, w, h);

        const dataURL =
          file.type === "image/jpeg"
            ? canvas.toDataURL(file.type, 0.9)
            : canvas.toDataURL(file.type);
        const stringData = dataURL.split(",")[1];
        const encImg = stringData.toString();
        const bindata = Buffer.from(encImg, "base64");

        form.setStatus({
          imageFormatError: "",
        });

        form.setFieldValue(field.name, {
          ...field.value,
          contentType: file.type,
          data: bindata,
        });
      };
      // @ts-ignore
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    form.setStatus({
      imageFormatError: i18n.t(
        "This is not a suported format. Please select an image."
      ),
    });
  }
};
