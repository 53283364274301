import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "../../../hooks/react-redux";

import { Divider, useTheme, ThemeProvider } from "@mui/material";

import { findIndex } from "lodash";

import AppDrawerView from "../../drawer/components/DrawerView";
import DrawerAccountPanel from "../../drawer/components/DrawerAccoutPanel";
import SettingsMenu from "../../drawer/components/SettingsMenu";

import MainAppBar from "../../appBars/components/MainAppBar";
import DialsContainers from "../../dialsContainer/containers/DialsContainer";
import AppTabs from "../../../components/AppTabs";
import AppTabsSkeleton from "../../../components/AppTabsSkeleton";

import * as globalActions from "../../../actions/global/actions";

import { setInStorage, getFromStorage } from "../../../services/storage";
import { getDrawerTheme } from "../../../getDrawerTheme";

const Main = (): JSX.Element => {
  const [appDrawerOpen, setAppDrawerOpen] = useState(true);

  const userData = useSelector((state) => state.users.user);
  const { groups, ...user } = userData;
  const cardCount = useSelector((state) => state.users.count);
  const tabValue = useSelector((state) => state.global.tabValue || 0);

  const largeScreen = useSelector((state) => state.global.largeScreen);

  const dispatch = useDispatch();

  const setTabValue = useCallback(
    (data: number) => {
      dispatch(globalActions.setTabValue({ tabValue: data }));
    },
    [dispatch]
  );

  useEffect(() => {
    setAppDrawerOpen(largeScreen);
  }, [largeScreen]);

  useEffect(() => {
    const value = findIndex(groups, ["_id", getFromStorage("currentGroup")]);
    value !== tabValue && setTabValue(value === -1 ? 0 : value);
  }, [groups, setTabValue, tabValue]);

  const handleChange = (_event: React.SyntheticEvent, value: number): void => {
    setTabValue(value);
    setInStorage("currentGroup", groups[value]._id);
  };

  const toggleDrawer = (open: boolean): void => {
    setAppDrawerOpen(open);
  };

  const mainTheme = useTheme();
  const theme = React.useMemo(() => getDrawerTheme(mainTheme), [mainTheme]);

  return (
    <>
      <MainAppBar
        groups={groups}
        toggleDrawer={toggleDrawer}
        appDrawerOpen={appDrawerOpen}
        tabValue={tabValue}
      />
      <ThemeProvider theme={theme}>
        <AppDrawerView
          appDrawerOpen={appDrawerOpen}
          toggleDrawer={toggleDrawer}
          largeScreen={largeScreen}
        >
          <DrawerAccountPanel user={user} />
          <Divider />
          {groups?.length > 0 ? (
            <AppTabs
              tabs={groups}
              onChange={handleChange}
              tabValue={tabValue}
            />
          ) : (
            <AppTabsSkeleton tabsCount={cardCount.groupsCount[0]?.count} />
          )}
          <Divider />
          <SettingsMenu />
        </AppDrawerView>
        <DialsContainers appDrawerOpen={appDrawerOpen} />
      </ThemeProvider>
    </>
  );
};

export default Main;
