import axios, { AxiosRequestConfig } from "axios";
import { setInStorage, getFromStorage, clearAllStorage } from "./storage";

interface CustomRequestConfig<T = any> extends AxiosRequestConfig {
  headers: Record<string, any>;
  data?: T;
}

const requestConfig: CustomRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: { Accept: "application/json" },
  },
};

export const myInstance = axios.create(requestConfig);

myInstance.interceptors.request.use(
  async function (config) {
    const accessToken = getFromStorage("accessToken");
    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
        common: {
          // @ts-ignore
          ...config.headers.common,
          Authorization: accessToken ? "Bearer " + accessToken : "",
        },
      },
    };

    return newConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
myInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === "/api/auth") {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === "/api/auth/token"
    ) {
      // We've already tried to get a new token
      clearAllStorage();
      document.location.assign("login");
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const tokens = {
        accessToken: getFromStorage("accessToken"),
        refreshToken: getFromStorage("refreshToken"),
      };
      const res = await myInstance.post("/api/auth/token", tokens);

      if (res.status === 201) {
        const { accessToken } = res.data;
        setInStorage("accessToken", accessToken);
        originalRequest.headers.Authorization = "Bearer " + accessToken;

        return axios(originalRequest);
      } else {
        clearAllStorage();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
