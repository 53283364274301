import { myInstance } from "../services/axiosInstance";

const apiEndPoint = "/api/preferences";

export type FetchResponse = {
  data: Preference.MainData;
};

export type UpdateResponse = FixMeLater;

type FetchReturn = { response?: FetchResponse; error?: ApiError };
type UpdateReturn = { response?: UpdateResponse; error?: ApiError };

export const apiCalls = {
  fetchByEmail: async (
    data: Preference.FetchRequestPayload
  ): Promise<FetchReturn> => {
    try {
      const email = encodeURIComponent(data.email);
      const response = await myInstance.get(apiEndPoint + "/" + email);
      return { response };
    } catch (error) {
      return { error };
    }
  },

  updateOne: async (payload: FixMeLater): Promise<UpdateReturn> => {
    return myInstance
      .put(apiEndPoint, payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
