import React from "react";
import {
  Avatar,
  AvatarProps,
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  Typography,
  CssBaseline,
  FormHelperText,
  Grid,
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import { Formik, Field, Form, FormikFormProps } from "formik";

import AppInput from "../../../components/form/AppInput";
import { validationSchema } from "../validationSchema";

const PREFIX = "LoginForm";

const StyledAvatar = styled(Avatar, {
  label: `${PREFIX}-avatar`,
})<AvatarProps>(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const StyledForm = styled(Form, {
  label: `${PREFIX}-form`,
})<FormikFormProps>(({ theme }) => ({
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(Button, {
  label: `${PREFIX}-form`,
})<ButtonProps>(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledRoot = styled("main", {
  label: `${PREFIX}-root`,
})(({ theme }) => ({
  width: "auto",
  display: "block", // Fix IE 11 issue.
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  [theme.breakpoints.up(300 + Number(theme.spacing(3 * 2).replace("px", "")))]:
    {
      width: 300,
      marginLeft: "auto",
      marginRight: "auto",
    },
}));

const StyledPaper = styled(Paper, {
  label: `${PREFIX}-paper`,
})<PaperProps>(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
}));

type LoginFormProps = {
  onSubmit: (v: Auth.FormData) => void;
  loginFailed: boolean;
};

const LoginForm = ({
  onSubmit,
  loginFailed,
}: LoginFormProps): React.ReactElement => {
  const { t } = useTranslation();

  const initialValues: Auth.FormData = {
    email: "",
    password: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      <StyledRoot>
        <CssBaseline />
        <StyledPaper>
          <StyledAvatar>
            <LockOutlinedIcon />
          </StyledAvatar>
          <Typography component="h1" variant="h5">
            {t("Sign in")}
          </Typography>
          <StyledForm>
            <Grid container justifyContent={"center"} spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={AppInput}
                  name="email"
                  type="email"
                  label={t("Email Address")}
                  isClearable={false}
                  autoFocus
                  fullWidth
                  placeholder=""
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={AppInput}
                  name="password"
                  type="password"
                  label={t("Password")}
                  isClearable={false}
                  fullWidth
                  placeholder=""
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: loginFailed ? "inherit" : "none" }}
              >
                <FormHelperText error={loginFailed} id="credential">
                  {t("Invalid email or password !")}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t("Sign in")}
                </StyledButton>
              </Grid>
            </Grid>
          </StyledForm>
        </StyledPaper>
      </StyledRoot>
    </Formik>
  );
};

export default LoginForm;
