// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// To be used in components to be able to test them with redux
// import {
//   useSelector as originalUseSelector,
//   useDispatch as originalUseDispatch,
// } from "react-redux";

// export const useSelector = (state) => originalUseSelector(state);
// export const useDispatch = () => originalUseDispatch();

import {
  TypedUseSelectorHook,
  useSelector as originalUseSelector,
  useDispatch as originalUseDispatch,
} from "react-redux";
import type { AppDispatch, IRootState } from "../store/store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => originalUseDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<IRootState> =
  originalUseSelector;
