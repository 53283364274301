import { takeEvery, call, put, StrictEffect } from "redux-saga/effects";

import { PING_API } from "./actionTypes";

import * as globalActions from "../global/actions";

import { apiCalls, PingResponse } from "../../apiCalls/rootApiCalls";

type PingReturn = { response: PingResponse; error: ApiError };

function* pingApiSaga(): Generator<StrictEffect, any, PingReturn> {
  const { response, error } = yield call(apiCalls.ping);
  if (response) {
    yield put(globalActions.setOnlineMode());
  } else {
    yield put(globalActions.apiFailure(error));
  }
}

export function* sagaWatcher(): Generator {
  yield takeEvery(PING_API, pingApiSaga);
}
