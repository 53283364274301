import React from "react";
import { Route } from "react-router-dom";
import Logout from "../login/containers/Logout";
import Main from "../main/containers/Main";
import Users from "../users/containers/Users";
import Groups from "../groups/containers/Groups";
import Themes from "../themes/containers/ThemeSelector";

const PrivateRoute = (): JSX.Element => {
  return (
    <div>
      <Route exact path="/" render={() => <Main />} />
      <Route exact path="/users" render={() => <Users />} />
      <Route exact path="/themes" render={() => <Themes />} />
      <Route exact path="/groups" render={() => <Groups />} />
      <Route exact path="/logout" render={() => <Logout />} />
    </div>
  );
};

export default PrivateRoute;
