// @ts-nocheck
import { takeEvery, call, put, StrictEffect } from "redux-saga/effects";
import * as prefsActionTypes from "./actionTypes";
import * as actions from "./actions";
import * as globalActions from "../global/actions";
import * as prefApi from "../../apiCalls/preferencesApiCalls";

type FetchReturn = { response: prefApi.FetchResponse; error: ApiError };
type UpdateReturn = { response: prefApi.UpdateResponse; error: ApiError };

function* fetchRequestSaga(
  action: Preference.FetchRequest
): Generator<StrictEffect, any, FetchReturn> {
  const { response, error } = yield call(
    prefApi.apiCalls.fetchByEmail,
    action.payload
  );
  if (response) {
    yield put(actions.fetchPrefSuccess(response.data));
  } else {
    console.error(error.response);
    yield put(globalActions.apiFailure(error));
  }
}

function* updateRequestSaga(
  action: Preference.UpdateRequest
): Generator<StrictEffect, any, UpdateReturn> {
  const { response, error } = yield call(
    prefApi.apiCalls.updateOne,
    action.payload
  );
  if (response) {
    yield put(actions.updatePrefSuccess(response.data));
  } else {
    console.error(error.response);
    yield put(globalActions.apiFailure(error));
  }
}

export function* sagaWatcher(): Generator {
  yield takeEvery(prefsActionTypes.FETCH_PREF_REQUEST, fetchRequestSaga);
  yield takeEvery(prefsActionTypes.UPDATE_PREF_REQUEST, updateRequestSaga);
}
