type Payload = {
  contentType: string;
  data?: { data: ArrayBufferLike; type: string };
};

export type Uint8ArrayToDataURL = (
  p: Payload | undefined
) => string | undefined;

export const uint8ArrayToDataURL: Uint8ArrayToDataURL = (payload) => {
  if (payload && payload.contentType) {
    // source : https://medium.com/@koteswar.meesala/convert-array-buffer-to-base64-string-to-display-images-in-angular-7-4c443db242cd
    // NB : When the image comes directly from Mysql the data is in image.data.data and there is an object image.data.type.
    //      When the image comes from the upload the data is in image.data and there is no object image.data.type.
    const data = (
      payload.data && payload.data.type ? payload.data.data : payload.data
    ) as ArrayBufferLike;

    const uint8data = new Uint8Array(data);

    const stringChar = uint8data.reduce((car, byte) => {
      return car + String.fromCharCode(byte);
    }, "");

    const base64 = btoa(stringChar);
    const imageType = payload.contentType ? payload.contentType : "image/jpg";
    const result = "data:" + imageType + ";base64," + base64;
    return result;
  } else {
    return undefined;
  }
};
