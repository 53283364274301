import { createTheme, Theme } from "@mui/material/styles";
import { deepOrange, blue } from "@mui/material/colors";

export const getDrawerTheme = (mainTheme: Theme): Theme => {
  const { palette, ...rest } = mainTheme;
  const theme = createTheme({
    palette: {
      mode: "dark",
      ...(palette.mode === "light"
        ? {
            divider: "rgba(0, 0, 0, 0.12)",
            primary: blue,
            text: { primary: "#424242", secondary: "#777777" },
            secondary: {
              main: "#dddddd",
            },
            background: {
              // default: "#dddddd",
              paper: "#ffffff",
            },
          }
        : {
            primary: deepOrange,
            background: {
              default: palette.primary.main,
              paper: "#424242",
            },
          }),
    },
    ...rest,
  });

  return theme;
};
