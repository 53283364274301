import React from "react";
import { useSelector, useDispatch } from "../hooks/react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  Grid,
} from "@mui/material";
import Warning from "@mui/icons-material/Warning";

import { useTranslation } from "react-i18next";
import * as globalActions from "../actions/global/actions";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

const AppAlertDialog = (): JSX.Element | null => {
  const alert = useSelector((state) => state.global.alert);

  const dispatch = useDispatch();

  const onCancel = (): void => {
    dispatch(globalActions.hideAlert());
  };

  const onConfirm = (): void => {
    dispatch({
      type: alert.confirmAction?.actionTypes,
      payload: alert.confirmAction?.payload,
    });
  };

  const { t } = useTranslation();

  const alertTypes:
    | Record<"delete" | "update", { alertTitle: string; alertText: string }>
    | Record<any, any> = {};

  alertTypes.delete = {
    alertTitle: t(
      "Do you really want to delete this item AND ALL ITS POSSIBLE CHILDREN?"
    ),
    alertText: t(
      "You are about to delete a record. This action cannot be undo."
    ),
  };

  alertTypes.update = {
    alertTitle: t("Do you really to update those changes?"),
    alertText: t(
      "You are about to delete a record. This action cannot be undo."
    ),
  };

  const title =
    alert.showAlert && alert.alertType
      ? alertTypes[alert.alertType].alertTitle
      : "";
  const message =
    alert.showAlert && alert.alertType
      ? alertTypes[alert.alertType].alertText
      : "";

  return alert?.showAlert ? (
    <Dialog open={Boolean(alert?.showAlert)} TransitionComponent={Transition}>
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid item xs={4}>
            <Warning color="error" style={{ fontSize: 60 }} />
          </Grid>
          <Grid item xs={8}>
            {title}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onConfirm}>
          {t("Yes")}
        </Button>
        <Button
          variant="contained"
          onClick={onCancel}
          color="primary"
          autoFocus
        >
          {t("No")}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default AppAlertDialog;
