import * as usersActions from "../actions/users/actions";

export const fetchAllGroupsWithLogos = (
  dispatch: any,
  userEmail: User.UserData["email"],
  groupList: User.GroupList[]
): void => {
  for (const group of groupList) {
    dispatch(
      usersActions.fetchGroupRequest({
        email: userEmail,
        groupId: group.value,
        withLogos: true,
      })
    );
  }
};
