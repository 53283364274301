import React from "react";
import { styled, Switch } from "@mui/material";
import MuiLightIcon from "@mui/icons-material/LightMode";
import MuiDarkIcon from "@mui/icons-material/DarkMode";

const LightIcon = styled(MuiLightIcon)({
  width: 24,
  height: 24,
  borderRadius: "50%",
  backgroundColor: "#1634d1",
  color: "#e9cb2e",
});

const DarkIcon = styled(MuiDarkIcon)({
  width: 24,
  height: 24,
  borderRadius: "50%",
  backgroundColor: "#0f056b",
  color: "#FCCC4D",
});

const MySwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 42,
  padding: theme.spacing(1),
  "& .MuiSwitch-track": {
    borderRadius: "12px",
  },
}));

export const ThemeSwitch = ({
  onThemeChange,
  mode,
}: {
  onThemeChange: VoidFunction;
  mode: Preference.ThemeMode;
}): React.ReactElement | null => {
  return (
    <MySwitch
      onClick={onThemeChange}
      color={mode === "dark" ? "secondary" : "primary"}
      value={mode === "light"}
      checkedIcon={<LightIcon />}
      icon={<DarkIcon />}
    />
  );
};
