import React from "react";
import { Typography, Grid, Tooltip } from "@mui/material";
import SignalWifiOff from "@mui/icons-material/SignalWifiOff";

export const OfflineIndicator = (): React.ReactElement => (
  <Tooltip title="Aucun reseau disponible !">
    <>
      <Grid item>
        <Typography variant="body1" color="error" noWrap>
          {" Offline"}
        </Typography>
      </Grid>
      <Grid item>
        <SignalWifiOff color="error" />
      </Grid>
    </>
  </Tooltip>
);
