import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  FormHelperText,
  Typography,
  IconButton,
  Theme,
} from "@mui/material";
import Clear from "@mui/icons-material/Clear";
import { useField } from "formik";
import { uint8ArrayToDataURL, fileUpload } from "../../services/utils";

const useStyles = makeStyles<Theme>((theme) => ({
  input: {
    display: "none",
  },
  image: {
    width: "300px",
    margin: "auto",
    position: "relative",
    height: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
    },
    "&:hover": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  imageSrc: {
    position: "absolute",
    maxHeight: "100%",
    maxWidth: "100%",
    padding: "2px",
  },
  imageSrcRounded: {
    position: "absolute",
    padding: "2px",
    borderRadius: "129px",
    width: "200px",
    height: "200px",
  },
  imageGrid: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
  },
  imageBackdrop: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: ".5",
    maxWidth: "300px",
    margin: "auto",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)} ${theme.spacing(4)} ${
      theme.spacing(1) + 6
    }px`,
  },
  imageError: {
    textAling: "center",
  },
}));

type ImageInputProps = {
  form: any;
  field: any;
  rounded: boolean;
  isClearable: boolean;
  label: string;
};

const ImageInput = ({
  form,
  field: formikField,
  label,
  rounded = false,
  isClearable = false,
}: ImageInputProps): JSX.Element => {
  const classes = useStyles();

  const [field] = useField(formikField);

  return (
    <>
      <label htmlFor="raised-button-file">
        <Grid container spacing={0} justifyContent="space-between">
          {isClearable && <Grid item xs={1} />}
          <Grid item xs={10}>
            <Button focusRipple component="span" className={classes.image}>
              <input
                accept="image/*"
                className={classes.input}
                type="file"
                id="raised-button-file"
                // @ts-ignore
                onChange={(files) => fileUpload({ files, field, form })}
              />
              {uint8ArrayToDataURL(field.value) && (
                <Grid
                  component="img"
                  className={
                    rounded ? classes.imageSrcRounded : classes.imageSrc
                  }
                  src={uint8ArrayToDataURL(field.value)}
                />
              )}
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {label || "Click to change"}
                </Typography>
              </span>
            </Button>
          </Grid>
          {isClearable && (
            <Grid item xs={1}>
              <IconButton
                aria-label="Clear"
                onClick={() => {
                  form.setFieldTouched(field.name, false);
                  form.setFieldError(field.name, "");
                  form.setFieldValue(field.name, { contentType: "", data: "" });
                }}
                size="large"
              >
                <Clear />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <FormHelperText
          className={classes.imageError}
          error={form.status && Boolean(form.status.imageFormatError)}
        >
          {form.status && form.status.imageFormatError}
        </FormHelperText>
      </label>
    </>
  );
};

export default ImageInput;
