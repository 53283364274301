import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TabIcon from "@mui/icons-material/Tab";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: "180px",
  },
  text: {
    color: theme.palette.text.secondary,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const SettingsMenu = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const settings = [
    { name: t("My account"), target: "/users", icon: <AccountCircleIcon /> },
    { name: t("Manage groups"), target: "/groups", icon: <TabIcon /> },
    // { name: t("Themes"), target: "/themes", icon: <PaletteIcon /> },
  ];

  return (
    <Grid
      container
      spacing={3}
      justifyContent="flex-start"
      className={classes.root}
    >
      <Grid item xs={4}>
        <List className={classes.root}>
          {settings.map((setting, i) => (
            <ListItem
              component={Link}
              color="secondary"
              to={setting.target}
              key={i}
              button
            >
              <ListItemIcon className={classes.icon}>
                {setting.icon}
              </ListItemIcon>
              <ListItemText
                id={i.toString()}
                className={classes.text}
                primary={setting.name}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default SettingsMenu;
