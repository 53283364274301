import React from "react";
import { Tabs, TabsProps, Tab, TabProps, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

import FolderIcon from "@mui/icons-material/Folder";

const PREFIX = "AppTabs";

const StyledTabs = styled(Tabs, {
  label: `${PREFIX}-tabs`,
})<TabsProps>(() => ({
  maxHeight: "60vh",
}));

const StyledIcon = styled("div", {
  label: `${PREFIX}-icon`,
})(() => ({
  minWidth: "56px",
  display: "flex",
}));

const StyledTab = styled(Tab, {
  label: `${PREFIX}-tab`,
})<TabProps>(({ theme }) => ({
  color: theme.palette.text.secondary,
  whiteSpace: "inherit",
  textTransform: "none",
  minHeight: "inherit",
  padding: "8px 16px 8px 16px",
  flexDirection: "row",
  justifyContent: "flex-start",
  overflowX: "hidden",
}));

const Icon = (): JSX.Element => {
  return (
    <StyledIcon>
      <FolderIcon />
    </StyledIcon>
  );
};

type LabelProps = { label: string };

const Label = ({ label }: LabelProps): JSX.Element => {
  return (
    <Typography noWrap color={"palette.text.primary"}>
      {label}
    </Typography>
  );
};

type AppTabsProps = {
  tabs: { name: string }[];
  onChange: (event: any, value: any) => void;
  tabValue: number;
};

const AppTabs = ({ tabs, onChange, tabValue }: AppTabsProps): JSX.Element => {
  return (
    <StyledTabs
      orientation="vertical"
      value={tabValue}
      onChange={onChange}
      // indicatorColor="primary"
      // textColor="primary"
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
    >
      {tabs &&
        tabs.map((tab, i) => (
          <StyledTab
            // sx={{ flexDirection: "row", justifyContent: "flex-start" }}
            wrapped={true}
            key={i}
            label={<Label label={tab.name} />}
            icon={<Icon />}
          />
        ))}
    </StyledTabs>
  );
};

export default AppTabs;
