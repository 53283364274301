import React from "react";
import { SwipeableDrawer, SwipeableDrawerProps, Grid } from "@mui/material";

import { styled } from "@mui/material/styles";

const drawerWidth = 220;

const PREFIX = "DrawerView";

const StyledDrawer = styled(SwipeableDrawer, {
  label: `${PREFIX}-drawer`,
})<SwipeableDrawerProps>(() => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
}));

type AppDrawerViewProps = {
  appDrawerOpen: boolean;
  toggleDrawer: (v: boolean) => void;
  largeScreen: boolean;
  children: React.ReactNode;
};

const AppDrawerView = ({
  appDrawerOpen,
  toggleDrawer,
  largeScreen,
  children,
}: AppDrawerViewProps): JSX.Element => {
  return (
    <StyledDrawer
      variant={largeScreen ? "persistent" : "temporary"}
      anchor="left"
      open={appDrawerOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="flex-start"
        onClick={() => toggleDrawer(largeScreen)}
        onKeyDown={() => toggleDrawer(largeScreen)}
      >
        {children}
      </Grid>
    </StyledDrawer>
  );
};

export default AppDrawerView;
