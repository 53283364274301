import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";

import { IconButton, AppBar, Toolbar, Typography, Theme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles<Theme>((theme) => ({
  appBar: {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const GroupsAppBar = ({ title }: { title: string }): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="secondary" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          component={Link}
          edge="start"
          to="/"
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default GroupsAppBar;
