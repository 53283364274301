import * as Yup from "yup";
import i18n from "../../i18n";

export const validationSchema = Yup.object({
  email: Yup.string()
    .email(i18n.t("Please enter a valid email address"))
    .nullable()
    .required(i18n.t("Please enter a email")),
  password: Yup.string().nullable().required(i18n.t("Please enter a password")),
});
