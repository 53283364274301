import React from "react";
import { Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles(() => ({
  tabs: {
    maxHeight: "60vh",
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    overflowX: "hidden",
  },
  root: {
    whiteSpace: "inherit",
    textTransform: "none",
  },
  labelIcon: {
    width: "199px",
    minHeight: "inherit",
    padding: "8px 16px 8px 16px",
  },
  icon: {
    minWidth: "56px",
    display: "flex",
  },
}));

type AppTabsSkeletonProps = {
  tabsCount: number;
};

const AppTabsSkeleton = ({ tabsCount }: AppTabsSkeletonProps): JSX.Element => {
  const classes = useStyles();

  const skeleton: JSX.Element[] = [];
  const genSkeleton = (j: number): JSX.Element[] => {
    for (let i = 0; i < j; i++) {
      skeleton.push(
        <Skeleton height="20px" style={{ margin: "10px" }} key={i} />
      );
    }
    return skeleton;
  };

  return (
    <Tabs
      className={classes.tabs}
      orientation="vertical"
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
    >
      {genSkeleton(tabsCount)}
    </Tabs>
  );
};

export default AppTabsSkeleton;
