import React from "react";
import { IconButton, IconButtonProps, styled } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

type MyIconButtonType = IconButtonProps & { appDrawerOpen: boolean };

const MyIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "appDrawerOpen",
})<MyIconButtonType>(({ theme, appDrawerOpen }) => ({
  marginRight: theme.spacing(2),
  display: appDrawerOpen ? "none" : "inherit",
}));

export const DrawerButton = ({
  toggleDrawer,
  appDrawerOpen,
}: {
  toggleDrawer: (v: boolean) => void;
  appDrawerOpen: boolean;
}): React.ReactElement => {
  return (
    <MyIconButton
      color="inherit"
      aria-label="open drawer"
      onClick={() => toggleDrawer(true)}
      edge="start"
      size="large"
      appDrawerOpen={appDrawerOpen}
    >
      <MenuIcon />
    </MyIconButton>
  );
};
