import * as usersActions from "../actions/users/actions";

export const fetchAllGroupsWithoutLogos = (
  dispatch: any,
  userEmail: User.UserData["email"]
): void => {
  dispatch(
    usersActions.fetchUserRequest({ email: userEmail, withLogos: false })
  );
};
