import React, { useCallback } from "react";
import { useSelector, useDispatch } from "../../../hooks/react-redux";

import * as preferencesActions from "../../../actions/preferences/actions";
import ThemeSelectorView from "../components/ThemeSelectorView";
import SettingsAppBar from "../../appBars/components/SettingsAppBar";
import { useTranslation } from "react-i18next";

const options: Option[] = [
  { value: "light", name: "Light theme" },
  { value: "dark", name: "Dark theme" },
  { value: "default", name: "System default" },
];

const ThemeSelector = (): JSX.Element => {
  const [t] = useTranslation();
  const theme = useSelector((state) => state.preferences.theme);
  const email = useSelector((state) => state.users.user.email);

  const dispatch = useDispatch();

  const setTheme = useCallback(
    (newTheme: string) => {
      const payload = { email, theme: newTheme };
      dispatch(preferencesActions.updatePrefRequest(payload));
    },
    [dispatch, email]
  );

  const handleChange = (_event: React.SyntheticEvent, option: Option): void => {
    setTheme(option.value);
  };

  const title = t("My Theme");

  return (
    <>
      <SettingsAppBar title={title} />
      <ThemeSelectorView
        options={options}
        theme={theme}
        onChange={handleChange}
      />
    </>
  );
};

export default ThemeSelector;
