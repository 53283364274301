import { sortGroupsAndDials } from "../../services/utils";
import * as actionTypes from "./actionTypes";
import {
  addCurrentToGroups,
  createDialSuccess,
  deleteDialSuccess,
  mergeGroupsWithoutLogosAndCurrentGroups,
  updateDialSuccess,
} from "./utils";
// import { updateGroupSuccess } from "./utils/updateGroupSuccess.ts-notused";

const initialValues: User.State = {
  user: {
    _id: "",
    email: "",
    name: "",
    firstname: "",
    avatar: undefined,
    password: undefined,
    groups: [],
  },
  groupsList: [],
  count: {
    groupsCount: [{ count: 0 }],
    dialsCount: [{ count: 0 }],
  },
};

export default function reducer(
  state = initialValues,
  action: User.Actions
): User.State {
  switch (action.type) {
    case actionTypes.FETCH_CURRENT_GROUP_SUCCESS: {
      const { groups } = action.payload;
      const orderedGroup = sortGroupsAndDials(groups);
      return {
        ...state,
        user: {
          ...state.user,
          groups: addCurrentToGroups(state.user.groups, orderedGroup[0]),
        },
      };
    }

    case actionTypes.FETCH_USER_WITH_LOGOS_SUCCESS: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { groups, password, ...user } = action.payload;
      const orderedGroup = sortGroupsAndDials(groups);
      return {
        ...state,
        user: { ...user, groups: orderedGroup },
      };
    }

    case actionTypes.FETCH_USER_WITHOUT_LOGOS_SUCCESS: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { groups, password, ...user } = action.payload;
      const orderedGroup = sortGroupsAndDials(groups);
      return {
        ...state,
        user: {
          ...user,
          groups: mergeGroupsWithoutLogosAndCurrentGroups(
            state.user.groups,
            orderedGroup
          ),
        },
        groupsList: orderedGroup.map((group) => ({
          value: group._id,
          name: group.name,
        })),
      };
    }

    case actionTypes.COUNT_DIALS_SUCCESS: {
      return {
        ...state,
        count: { ...action.payload[0] },
      };
    }

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: { ...action.payload, groups: [...state.user.groups] },
      };

    case actionTypes.UPDATE_ALLGROUPS_SUCCESS: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { groups } = action.payload;
      const orderedGroup = sortGroupsAndDials(groups);
      return {
        ...state,
        user: {
          ...state.user,
          groups: mergeGroupsWithoutLogosAndCurrentGroups(
            orderedGroup,
            state.user.groups
          ),
        },
        groupsList: orderedGroup.map((group) => ({
          value: group._id,
          name: group.name,
        })),
      };
    }

    case actionTypes.UPDATE_DIAL_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          groups: updateDialSuccess(state, action.payload),
        },
      };
    }

    case actionTypes.CREATE_DIAL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          groups: createDialSuccess(state, action.payload),
        },
      };

    case actionTypes.DELETE_DIAL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          groups: deleteDialSuccess(state, action.payload),
        },
      };

    // case actionTypes.UPDATE_GROUP_SUCCESS:
    //   return {
    //     ...state,
    //     groups: updateGroupSuccess(state, action.payload).groups,
    //     groupsList: updateGroupSuccess(state, action.payload).groupsList,
    //   };

    case actionTypes.CLEAR_ALL_USER:
      return initialValues;

    default:
      return state;
  }
}
