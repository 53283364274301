/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, Field, Form } from "formik";

import AppInput from "../../../components/form/AppInput";
import AppButtonUploadImage from "../../../components/form/AppButtonUploadImage";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: "100px 30px 8px 30px",
  },
  appBar: {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  imageGrid: {
    display: "flex",
    justifyContent: "center",
    height: "calc(160px + " + theme.spacing(2) + "px)",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  saveButton: {
    maxWidth: "150px",
    justifyContent: "center",
  },
  saveButtonContainer: {
    justifyContent: "center",
    display: "flex",
  },
}));

type UserFormProps = {
  user: Omit<User.UserData, "groups">;
  validationSchema: any;
  actions: { onSubmit: any };
};

const UserForm = ({ user, validationSchema, actions }: UserFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const initialValues: Partial<User.FormData> = {
    _id: user._id || undefined,
    name: user.name || "",
    firstname: user.firstname || "",
    email: user.email || "",
    password: user.password || "",
    passwordC: user.password || "",
    avatar: user.avatar || {
      contentType: "",
    },
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        actions.onSubmit(values);
      }}
    >
      <Form>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12} sm={4}>
            <Field
              component={AppButtonUploadImage}
              name="avatar"
              rounded={true}
              isClearable={true}
              actions={actions}
              label={t("Click to set your Avatar")}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={6} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <Field
                  component={AppInput}
                  name="name"
                  label={t("Lastname")}
                  type="text"
                  placeholder=""
                  autoFocus
                  fullWidth
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={AppInput}
                  name="firstname"
                  label={t("Firstname")}
                  type="text"
                  placeholder=""
                  fullWidth
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={AppInput}
                  name="email"
                  label={t("Email")}
                  type="text"
                  placeholder=""
                  fullWidth
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={AppInput}
                  name="password"
                  label={t("Password")}
                  type="password"
                  placeholder=""
                  fullWidth
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={AppInput}
                  name="passwordC"
                  label={t("Confirm Password")}
                  type="password"
                  placeholder=""
                  fullWidth
                  isClearable={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.saveButtonContainer}>
            <Button
              fullWidth
              type="submit"
              variant="outlined"
              color="primary"
              className={classes.saveButton}
            >
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default UserForm;
