import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { getObjectFromValue } from "../../../services/utils";

import AppAutocomplete from "../../../components/form/AppAutoComplete";

const useStyles = makeStyles(() => ({
  root: {
    padding: "100px 30px 8px 30px",
  },
  formLabel: {
    margin: "auto",
  },
}));

type ThemeSelectorViewProps = {
  options: Option[];
  theme: string;
  onChange: (e: React.SyntheticEvent, v: Option) => void;
};

const ThemeSelectorView = ({
  options,
  theme,
  onChange,
}: ThemeSelectorViewProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} sm={6} md={4} xl={2}>
        <AppAutocomplete
          options={options}
          name="theme"
          value={getObjectFromValue(theme, options)}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default ThemeSelectorView;
