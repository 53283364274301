import { findIndex } from "lodash";

export const createDialSuccess = (
  state: User.State,
  payload: User.CreateDialSuccessPayload
): User.Group[] => {
  const { groupId, dial } = payload;
  const groups = [...state.user.groups];
  const groupIdx = findIndex(groups, ["_id", groupId]);

  groups[groupIdx].dials?.push(dial);

  return groups;
};
