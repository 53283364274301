import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import packageInfos from "../../../../package.json";

const useStyles = makeStyles({
  versionNumber: {
    paddingLeft: 8,
    alignSelf: "center",
  },
});

const Version = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography
      component="p"
      variant="caption"
      className={classes.versionNumber}
    >
      v{packageInfos.version}
    </Typography>
  );
};

export default Version;
