import React from "react";
import { Route, Redirect } from "react-router-dom";
import Login from "../login/containers/Login";

const PublicRoutes = ({ isAuth }: { isAuth: boolean }): JSX.Element => {
  return (
    <div>
      <Route
        // exact
        path="/"
        render={(props) => !isAuth && <Login {...props} />}
      />
      <Route exact path="/login" render={() => <Redirect to="/" />} />
    </div>
  );
};

export default PublicRoutes;
