import React, { useRef } from "react";

import GroupItemView from "../components/GroupItemView";

import { useDrag, useDrop } from "react-dnd";
import { DndItemTypes } from "../../DndItemTypes";

type GroupItemProps = {
  actions: Group.FormActions;
  group: User.Group;
  index: number;
};

const GroupItem = ({ actions, group, index }: GroupItemProps): JSX.Element => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: DndItemTypes.GROUP,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      // @ts-ignore
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      // @ts-ignore
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // @ts-ignore
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      actions.moveGroup(dragIndex, hoverIndex);
      // @ts-ignore
      item.index = hoverIndex;
    },
    drop: () => ({ hoverIndex: index }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: DndItemTypes.GROUP,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <>
      <GroupItemView
        ref={ref}
        isDragging={isDragging}
        actions={actions}
        group={group}
        index={index}
      />
    </>
  );
};

export default GroupItem;
