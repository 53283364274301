import React, { Fragment, useEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { useTranslation } from "react-i18next";
import { Alert, Button, Slide, Snackbar } from "@mui/material";

const ServiceWorkerWrapper = (): React.ReactElement => {
  const { t } = useTranslation();
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration): void => {
    setWaitingWorker(registration.waiting);
    setShowReload(true);
  };

  const onSWInit = (registration: ServiceWorkerRegistration): void => {
    if (registration.waiting) {
      setWaitingWorker(registration.waiting);
      setShowReload(true);
    }
  };

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
      onInit: onSWInit,
    });
  }, []);

  const reloadPage = React.useCallback((): void => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  }, [waitingWorker]);

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={showReload}
        TransitionComponent={Slide}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="warning"
          action={
            <React.Fragment>
              <Button variant="text" size="small" onClick={reloadPage}>
                {t("UPDATE")}
              </Button>
            </React.Fragment>
          }
        >
          {t("NEW_RELEASE_AVAILABLE")}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default ServiceWorkerWrapper;
